"use client";
import { useQuery } from "@apollo/client";
import { mapInvoiceEntity } from "../mappers/mapInvoiceEntity";
import { FIND_MY_UPCOMING_INVOICE } from "../queries/findMyUpcomingInvoice";
var initialInvoiceValues = mapInvoiceEntity();
export var useFindMyUpcomingInvoice = function () {
    var _a = useQuery(FIND_MY_UPCOMING_INVOICE, { fetchPolicy: "network-only" }), data = _a.data, loading = _a.loading;
    return {
        invoice: loading
            ? initialInvoiceValues
            : ((data === null || data === void 0 ? void 0 : data.findMyUpcomingInvoice) &&
                mapInvoiceEntity(data.findMyUpcomingInvoice)) ||
                initialInvoiceValues,
        loading: loading,
    };
};
