"use client";
import { useQuery } from "@apollo/client";
import { FIND_MY_ORG_STRIPE_TRANSACTIONS } from "../queries/findMyOrgStripeTransactions";
export var useFindMyOrgStripeTransactions = function () {
    var _a = useQuery(FIND_MY_ORG_STRIPE_TRANSACTIONS, {
        fetchPolicy: "cache-first",
    }), data = _a.data, error = _a.error, loading = _a.loading;
    return {
        transactions: loading ? [] : (data === null || data === void 0 ? void 0 : data.findMyOrgStripeTransactions) || [],
        error: error,
        loading: loading,
    };
};
