"use client";
import { useQuery } from "@apollo/client";
import { FIND_GROUP_SETTINGS } from "../queries/findGroupSettings";
export var useFindGroupSettings = function (groupId) {
    var _a = useQuery(FIND_GROUP_SETTINGS, {
        variables: { groupId: groupId },
        fetchPolicy: "cache-first",
    }), data = _a.data, error = _a.error, loading = _a.loading, refetch = _a.refetch;
    return {
        settings: loading ? null : (data === null || data === void 0 ? void 0 : data.findGroupSettings) || null,
        error: error,
        loading: loading,
        refetch: refetch,
    };
};
