import { useMutation } from "@apollo/client";
import { GENERATE_TEAM_API_KEY } from "../mutations/generateTeamApiKey";
export var useGenerateTeamApiKey = function () {
    var _a = useMutation(GENERATE_TEAM_API_KEY, {
        update: function (cache, _a) {
            var data = _a.data;
            if (data === null || data === void 0 ? void 0 : data.generateTeamApiKey) {
                cache.modify({
                    id: cache.identify(data.generateTeamApiKey),
                    fields: {
                        apiKey: function () { return data.generateTeamApiKey.apiKey; },
                    },
                });
            }
        },
    }), generateTeamApiKey = _a[0], _b = _a[1], data = _b.data, loading = _b.loading, error = _b.error;
    return { generateTeamApiKey: generateTeamApiKey, data: data, loading: loading, error: error };
};
