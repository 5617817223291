"use client";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useQuery } from "@apollo/client";
import { initialRating } from "../constants";
import { FIND_MY_RATING } from "../queries/findMyRating";
import { RATING_UPDATED } from "../subscriptions/ratingUpdated";
export var useFindMyRating = function () {
    var _a = useQuery(FIND_MY_RATING), data = _a.data, loading = _a.loading, subscribeToMore = _a.subscribeToMore;
    var subscribeToRating = function (userId) {
        subscribeToMore({
            document: RATING_UPDATED,
            variables: { userId: userId },
            updateQuery: function (prev, _a) {
                var subscriptionData = _a.subscriptionData;
                if (!subscriptionData.data)
                    return prev;
                var ratingUpdated = subscriptionData.data.ratingUpdated;
                return {
                    findMyRating: __assign(__assign({}, prev.findMyRating), ratingUpdated),
                };
            },
        });
    };
    return {
        subscribeToRating: subscribeToRating,
        rating: loading ? initialRating : (data === null || data === void 0 ? void 0 : data.findMyRating) || initialRating,
    };
};
