"use client";
import { useQuery } from "@apollo/client";
import { FIND_PUBLIC_LESSON_TASK_TEMPLATES } from "../queries/findPublicLessonTaskTemplates";
export var useFindPublicLessonTaskTemplates = function (lessonTemplateId) {
    var _a = useQuery(FIND_PUBLIC_LESSON_TASK_TEMPLATES, {
        variables: {
            lessonTemplateId: lessonTemplateId,
        },
    }), data = _a.data, loading = _a.loading, refetch = _a.refetch, error = _a.error;
    return {
        taskTemplates: loading ? [] : (data === null || data === void 0 ? void 0 : data.findPublicLessonTaskTemplates) || [],
        loading: loading,
        refetch: refetch,
        error: error,
    };
};
