"use client";
import { useQuery } from "@apollo/client";
import { taskAssigneeMapper } from "../mappers/taskAssigneeMapper";
import { FIND_TASK_ASSIGNEE } from "../queries/findTaskAssignee";
export var useFindTaskAssignee = function (taskId) {
    var _a = useQuery(FIND_TASK_ASSIGNEE, {
        variables: {
            taskId: taskId,
        },
        fetchPolicy: "cache-first",
    }), data = _a.data, loading = _a.loading, refetch = _a.refetch;
    return {
        assignee: !loading
            ? ((data === null || data === void 0 ? void 0 : data.findMyTask) && taskAssigneeMapper(data.findMyTask)) || null
            : null,
        loading: loading,
        refetch: refetch,
    };
};
