"use client";
import { useQuery } from "@apollo/client";
import { FIND_GROUP_UPSELLS } from "../mutations/findGroupUpsells";
export var useFindGroupUpsells = function (groupId) {
    var _a = useQuery(FIND_GROUP_UPSELLS, {
        variables: { groupId: groupId },
        fetchPolicy: "cache-first",
    }), data = _a.data, error = _a.error, loading = _a.loading, refetch = _a.refetch;
    return {
        upsells: loading ? [] : (data === null || data === void 0 ? void 0 : data.findGroupUpsells) || [],
        error: error,
        loading: loading,
        refetch: refetch,
    };
};
