"use client";
import { useQuery } from "@apollo/client";
import { FIND_MY_PROGRAMS } from "../queries/findMyPrograms";
export var useFindMyPrograms = function () {
    var _a = useQuery(FIND_MY_PROGRAMS, {
        fetchPolicy: "network-only",
    }), data = _a.data, error = _a.error, loading = _a.loading, refetch = _a.refetch;
    return {
        programsWithProgress: loading ? [] : (data === null || data === void 0 ? void 0 : data.findMyPrograms) || [],
        error: error,
        loading: loading,
        refetch: refetch,
    };
};
