"use client";
import { useQuery } from "@apollo/client";
import { FIND_MY_ORG_STRIPE_ACCOUNT } from "../queries/findMyOrgStripeAccount";
export var useFindMyOrgStripeAccount = function () {
    var _a;
    var _b = useQuery(FIND_MY_ORG_STRIPE_ACCOUNT, {
        pollInterval: 30000,
    }), data = _b.data, error = _b.error, loading = _b.loading;
    return {
        account: loading ? null : (data === null || data === void 0 ? void 0 : data.findMyOrgStripeAccount) || null,
        isStripeConnected: (_a = data === null || data === void 0 ? void 0 : data.findMyOrgStripeAccount) === null || _a === void 0 ? void 0 : _a.detailsSubmitted,
        error: error,
        loading: loading,
    };
};
