"use client";
import { useQuery } from "@apollo/client";
import { initialSubscriptionItem } from "../constants";
import { FIND_MY_SUBSCRIPTION_ITEM } from "../queries/findMySubscriptionItem";
export var useFindMySubscriptionItem = function () {
    var _a = useQuery(FIND_MY_SUBSCRIPTION_ITEM, { fetchPolicy: "cache-first" }), data = _a.data, loading = _a.loading;
    return {
        item: loading
            ? initialSubscriptionItem
            : (data === null || data === void 0 ? void 0 : data.findMySubscriptionItem) || initialSubscriptionItem,
        loading: loading,
    };
};
