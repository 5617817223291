"use client";
import { useQuery } from "@apollo/client";
import { CALCULATE_MY_PROGRAM_PROGRESS } from "../queries/calculateMyProgramProgress";
import { PROGRAM_PROGRESS_UPDATED } from "../subscriptions/progressUpdated";
var initialProgress = 0;
export var useCalculateMyProgramProgress = function () {
    var _a = useQuery(CALCULATE_MY_PROGRAM_PROGRESS), data = _a.data, loading = _a.loading, subscribeToMore = _a.subscribeToMore;
    var subscribeToProgramProgress = function (userId) {
        subscribeToMore({
            document: PROGRAM_PROGRESS_UPDATED,
            variables: { userId: userId },
            updateQuery: function (prev, _a) {
                var subscriptionData = _a.subscriptionData;
                if (!subscriptionData.data)
                    return prev;
                var programProgressRecalculated = subscriptionData.data.programProgressRecalculated;
                return {
                    calculateMyProgramProgress: programProgressRecalculated,
                };
            },
        });
    };
    return {
        subscribeToProgramProgress: subscribeToProgramProgress,
        programProgressInPercent: loading
            ? initialProgress
            : (data === null || data === void 0 ? void 0 : data.calculateMyProgramProgress.programProgressInPercent) ||
                initialProgress,
    };
};
