"use client";
import { useQuery } from "@apollo/client";
import { initialProgram } from "../constants";
import { FIND_GROUP_PROGRAM } from "../queries/findGroupProgram";
export var useFindGroupProgram = function (groupId) {
    var _a = useQuery(FIND_GROUP_PROGRAM, {
        variables: { groupId: groupId },
        fetchPolicy: "network-only",
    }), data = _a.data, error = _a.error, loading = _a.loading, refetch = _a.refetch;
    return {
        program: loading
            ? initialProgram
            : (data === null || data === void 0 ? void 0 : data.findGroupProgram) || initialProgram,
        error: error,
        loading: loading,
        refetch: refetch,
    };
};
