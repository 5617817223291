import { defaultTaskTemplate } from "../task-template/constants";
export var initialTaskSchedule = {
    id: "",
    lessonScheduleId: "",
    state: "",
    tasks: [],
    taskTemplate: defaultTaskTemplate,
    taskTemplateId: "",
    assignee: null,
};
