"use client";
import { useQuery } from "@apollo/client";
import { FIND_GROUP_PROJECT_SCHEDULES } from "../queries/findGroupProjectSchedulesByProgramId";
export var useFindGroupProjectSchedules = function (programId) {
    var _a = useQuery(FIND_GROUP_PROJECT_SCHEDULES, {
        variables: { programId: programId },
        fetchPolicy: "cache-and-network",
    }), data = _a.data, loading = _a.loading, refetch = _a.refetch, error = _a.error;
    return {
        projectSchedules: loading
            ? []
            : (data === null || data === void 0 ? void 0 : data.findGroupProjectSchedulesByProgramId) || [],
        loading: loading,
        refetch: refetch,
        error: error,
    };
};
