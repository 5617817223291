import { initialLessonTemplateValues } from "../lesson-template/constants";
import { SchedulingState } from "../types/graphql";
export var initialLessonSchedule = {
    id: "",
    lessonTemplate: initialLessonTemplateValues,
    lessonTemplateId: "",
    projectScheduleId: "",
    state: SchedulingState.HIDDEN,
    taskSchedules: [],
    lessons: [],
};
