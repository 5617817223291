import { initialDocumentValues } from "../document/constants";
import { GithubRepoDistribution, } from "../types/graphql";
export var initialProjectTemplate = {
    githubRepoDistribution: GithubRepoDistribution.TEMPLATE,
    type: "",
    brief: initialDocumentValues,
    briefId: "",
    tags: [],
    authorId: "",
    isExpGainOn: false,
    githubRepoTemplateName: "",
    organisationId: "",
    isPublished: false,
    evaluations: [],
    livePreviewUrl: "",
    viewOnMarketplace: false,
    state: "",
    difficultyLevel: "",
    eta: 0,
    createdAt: "",
    orderIndex: 0,
    lessonTemplates: [],
    name: "",
    theme: "",
    id: "",
};
