"use client";
import { useQuery } from "@apollo/client";
import { mapProjectWithProgressToProject, } from "../..";
import { initialProjectValue } from "../constants";
import { FIND_PROJECT_WITH_PROGRESS } from "../queries/findProject";
export var useFindProject = function (projectId) {
    var _a = useQuery(FIND_PROJECT_WITH_PROGRESS, {
        variables: { projectId: projectId },
        fetchPolicy: "cache-and-network",
    }), data = _a.data, error = _a.error, loading = _a.loading, refetch = _a.refetch;
    return {
        project: loading
            ? initialProjectValue
            : ((data === null || data === void 0 ? void 0 : data.findProject) &&
                mapProjectWithProgressToProject(data.findProject)) ||
                initialProjectValue,
        error: error,
        loading: loading,
        refetch: refetch,
    };
};
