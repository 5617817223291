export var initialSubscription = {
    createdAt: undefined,
    id: "",
    seats: 0,
    organisationId: "",
    isOnExpirationPeriod: false,
    stripeCustomerId: "",
    plan: "",
    price: 0,
    updatedAt: undefined,
};
export var initialOrg = {
    avatarSrc: "",
    createdAt: "",
    email: "",
    id: "",
    isOnboardingComplete: false,
    subscription: initialSubscription,
    name: "",
    members: [],
};
