"use client";
import { useQuery } from "@apollo/client";
import { initialGroup } from "../constants";
import { FIND_MY_GROUP } from "../queries/findMyGroup";
export var useFindMyGroup = function (id) {
    var _a = useQuery(FIND_MY_GROUP, {
        variables: { id: id },
        fetchPolicy: "cache-first",
    }), data = _a.data, error = _a.error, loading = _a.loading, refetch = _a.refetch;
    return {
        group: loading ? initialGroup : (data === null || data === void 0 ? void 0 : data.findMyGroup) || initialGroup,
        error: error,
        loading: loading,
        refetch: refetch,
    };
};
