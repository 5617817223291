"use client";
import { useQuery } from "@apollo/client";
import { FIND_MY_ORG_INVOICE_DETAILS } from "../queries/findMyOrgInvoiceDetails";
export var useFindMyOrgInvoiceDetails = function () {
    var _a;
    var _b = useQuery(FIND_MY_ORG_INVOICE_DETAILS), data = _b.data, error = _b.error, loading = _b.loading, refetch = _b.refetch;
    return {
        invoiceDetails: loading
            ? null
            : ((_a = data === null || data === void 0 ? void 0 : data.findMyOrganisation) === null || _a === void 0 ? void 0 : _a.invoiceDetails) || null,
        error: error,
        loading: loading,
        refetch: refetch,
    };
};
