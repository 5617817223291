"use client";
import { useQuery } from "@apollo/client";
import { initialOrg } from "../constants";
import { FIND_MY_ORGANISATION } from "../queries/findMyOrganisation";
export var useFindMyOrganisation = function () {
    var _a = useQuery(FIND_MY_ORGANISATION), data = _a.data, error = _a.error, loading = _a.loading, refetch = _a.refetch;
    return {
        organisation: loading ? initialOrg : (data === null || data === void 0 ? void 0 : data.findMyOrganisation) || initialOrg,
        error: error,
        loading: loading,
        refetch: refetch,
    };
};
