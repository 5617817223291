"use client";
import { useQuery } from "@apollo/client";
import { initialPageData } from "../constants";
import { mapGroupPageEntityToGroupPageData } from "../mappers";
import { FIND_PUBLIC_GROUP_PAGE } from "../queries";
export var useFindPublicPage = function (slug) {
    var _a = useQuery(FIND_PUBLIC_GROUP_PAGE, {
        variables: { slug: slug },
    }), data = _a.data, loading = _a.loading;
    return {
        page: loading
            ? initialPageData
            : (data === null || data === void 0 ? void 0 : data.findPublicGroupPage)
                ? mapGroupPageEntityToGroupPageData(data.findPublicGroupPage)
                : initialPageData,
    };
};
