"use client";
import { useQuery } from "@apollo/client";
import { initialDocumentValues } from "../../document/constants";
import { GET_PUBLIC_GROUP_PAGE_DOCUMENT } from "../queries/findPublicGroupPageDocument";
export var useFindGroupPageDocument = function (pageId) {
    var _a = useQuery(GET_PUBLIC_GROUP_PAGE_DOCUMENT, {
        variables: { pageId: pageId },
        fetchPolicy: "network-only",
    }), data = _a.data, error = _a.error, loading = _a.loading, refetch = _a.refetch;
    return {
        document: loading
            ? initialDocumentValues
            : (data === null || data === void 0 ? void 0 : data.findPublicGroupPageDocument) || initialDocumentValues,
        error: error,
        loading: loading,
        refetch: refetch,
    };
};
