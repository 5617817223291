"use client";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useQuery } from "@apollo/client";
import isEqual from "lodash/isEqual";
import { PaymentMode, PublishState, } from "../../types/graphql";
import { initialPageData } from "../constants";
import { mapGroupPageEntityToGroupPageData } from "../mappers";
import { FIND_EDITABLE_PUBLIC_GROUP_PAGE } from "../queries";
export var useFindMyPageDraft = function (slug, initialValues) {
    if (initialValues === void 0) { initialValues = initialPageData; }
    var _a = useQuery(FIND_EDITABLE_PUBLIC_GROUP_PAGE, {
        variables: { slug: slug },
    }), data = _a.data, loading = _a.loading, cache = _a.client.cache;
    var pageDraft = ((data === null || data === void 0 ? void 0 : data.findEditablePublicGroupPage) &&
        mapGroupPageEntityToGroupPageData(data.findEditablePublicGroupPage)) ||
        initialValues;
    var getPageDraftFromCache = function () {
        var pageDraftCached = cache.readQuery({
            query: FIND_EDITABLE_PUBLIC_GROUP_PAGE,
            variables: { slug: slug },
        });
        if (!(pageDraftCached === null || pageDraftCached === void 0 ? void 0 : pageDraftCached.findEditablePublicGroupPage)) {
            console.warn("Page not found in cache");
            return null;
        }
        return pageDraftCached.findEditablePublicGroupPage;
    };
    var updatePageDraftInCache = function (values) {
        var pageDraftCached = getPageDraftFromCache();
        if (!pageDraftCached) {
            console.warn("Page not found in cache");
            return;
        }
        cache.writeQuery({
            query: FIND_EDITABLE_PUBLIC_GROUP_PAGE,
            variables: { slug: slug },
            data: {
                findEditablePublicGroupPage: __assign(__assign({}, pageDraftCached), values),
            },
        });
    };
    var handleUpdateShortDsc = function (value) {
        if (value === pageDraft.shortDsc)
            return;
        updatePageDraftInCache({
            state: PublishState.AWAITING_UPDATE,
            shortDsc: value,
        });
    };
    var handleToggleSubscription = function (isSubscriptionOn) {
        var pageDraftCached = getPageDraftFromCache();
        if (!(pageDraftCached === null || pageDraftCached === void 0 ? void 0 : pageDraftCached.group))
            return;
        updatePageDraftInCache(__assign(__assign({}, pageDraftCached), { state: PublishState.AWAITING_UPDATE, group: __assign(__assign({}, pageDraftCached.group), { paymentMode: isSubscriptionOn
                    ? PaymentMode.SUBSCRIPTION
                    : PaymentMode.PAYMENT }) }));
    };
    var handleToggleShowPrice = function (showPrice) {
        var pageDraftCached = getPageDraftFromCache();
        if (!(pageDraftCached === null || pageDraftCached === void 0 ? void 0 : pageDraftCached.group))
            return;
        updatePageDraftInCache(__assign(__assign({}, pageDraftCached), { state: PublishState.AWAITING_UPDATE, showPrice: showPrice }));
    };
    var handleUpdateCoverType = function (type) {
        if (type === pageDraft.coverType)
            return;
        var pageDraftCached = getPageDraftFromCache();
        if (!(pageDraftCached === null || pageDraftCached === void 0 ? void 0 : pageDraftCached.group))
            return;
        updatePageDraftInCache(__assign(__assign({}, pageDraftCached), { coverType: type, state: PublishState.AWAITING_UPDATE }));
    };
    var handleUpdateMediaSrc = function (src) {
        if (src === pageDraft.coverMediaSrc)
            return;
        var pageDraftCached = getPageDraftFromCache();
        if (!(pageDraftCached === null || pageDraftCached === void 0 ? void 0 : pageDraftCached.group))
            return;
        updatePageDraftInCache(__assign(__assign({}, pageDraftCached), { coverMediaSrc: src, state: PublishState.AWAITING_UPDATE }));
    };
    var handleUpdateCurrency = function (currency) {
        if (currency === pageDraft.currency)
            return;
        var pageDraftCached = getPageDraftFromCache();
        if (!(pageDraftCached === null || pageDraftCached === void 0 ? void 0 : pageDraftCached.group))
            return;
        updatePageDraftInCache(__assign(__assign({}, pageDraftCached), { state: PublishState.AWAITING_UPDATE, group: __assign(__assign({}, pageDraftCached.group), { currency: currency }) }));
    };
    var handleUpdatePricePerSeat = function (pricePerSeat) {
        if (pricePerSeat === pageDraft.pricePerSeat)
            return;
        var pageDraftCached = getPageDraftFromCache();
        if (!(pageDraftCached === null || pageDraftCached === void 0 ? void 0 : pageDraftCached.group))
            return;
        updatePageDraftInCache(__assign(__assign({}, pageDraftCached), { state: PublishState.AWAITING_UPDATE, group: __assign(__assign({}, pageDraftCached.group), { pricePerSeat: pricePerSeat }) }));
    };
    var handleUpdateMaxSeats = function (maxSeats) {
        var _a;
        if (maxSeats === pageDraft.maxSeats)
            return;
        var pageDraftCached = getPageDraftFromCache();
        if (!(pageDraftCached === null || pageDraftCached === void 0 ? void 0 : pageDraftCached.group) || !((_a = pageDraftCached === null || pageDraftCached === void 0 ? void 0 : pageDraftCached.group) === null || _a === void 0 ? void 0 : _a.settings))
            return;
        updatePageDraftInCache(__assign(__assign({}, pageDraftCached), { state: PublishState.AWAITING_UPDATE, group: __assign(__assign({}, pageDraftCached.group), { settings: __assign(__assign({}, pageDraftCached.group.settings), { maxSeats: maxSeats }) }) }));
    };
    var handleUpdateProgramPreviewId = function (previewGroupId) {
        var _a;
        if (previewGroupId === pageDraft.previewGroupId)
            return;
        var pageDraftCached = getPageDraftFromCache();
        if (!(pageDraftCached === null || pageDraftCached === void 0 ? void 0 : pageDraftCached.group) || !((_a = pageDraftCached === null || pageDraftCached === void 0 ? void 0 : pageDraftCached.group) === null || _a === void 0 ? void 0 : _a.settings))
            return;
        updatePageDraftInCache(__assign(__assign({}, pageDraftCached), { state: PublishState.AWAITING_UPDATE, group: __assign(__assign({}, pageDraftCached.group), { settings: __assign(__assign({}, pageDraftCached.group.settings), { previewGroupId: previewGroupId }) }) }));
    };
    var handleUpdateEmailContactList = function (contactListId) {
        if (contactListId === pageDraft.contactListId)
            return;
        var pageDraftCached = getPageDraftFromCache();
        if (!(pageDraftCached === null || pageDraftCached === void 0 ? void 0 : pageDraftCached.group))
            return;
        updatePageDraftInCache(__assign(__assign({}, pageDraftCached), { contactListId: contactListId, state: PublishState.AWAITING_UPDATE, group: __assign({}, pageDraftCached.group) }));
    };
    var handleUpdateCtaButtonType = function (ctaButtonType) {
        if (ctaButtonType === pageDraft.ctaButtonType)
            return;
        var pageDraftCached = getPageDraftFromCache();
        if (!(pageDraftCached === null || pageDraftCached === void 0 ? void 0 : pageDraftCached.group))
            return;
        updatePageDraftInCache(__assign(__assign({}, pageDraftCached), { ctaButtonType: ctaButtonType, state: PublishState.AWAITING_UPDATE }));
    };
    var handleUpdateMentorCalendarUrl = function (mentorCalendarUrl) {
        var _a;
        if (mentorCalendarUrl === pageDraft.mentorCalendarUrl)
            return;
        var pageDraftCached = getPageDraftFromCache();
        if (!(pageDraftCached === null || pageDraftCached === void 0 ? void 0 : pageDraftCached.group) || !((_a = pageDraftCached === null || pageDraftCached === void 0 ? void 0 : pageDraftCached.group) === null || _a === void 0 ? void 0 : _a.settings))
            return;
        updatePageDraftInCache(__assign(__assign({}, pageDraftCached), { state: PublishState.AWAITING_UPDATE, group: __assign(__assign({}, pageDraftCached.group), { settings: __assign(__assign({}, pageDraftCached.group.settings), { mentorCalendarUrl: mentorCalendarUrl }) }) }));
    };
    var handleToggleAutoAccess = function (allowAutoAccess) {
        var _a;
        var pageDraftCached = getPageDraftFromCache();
        if (!(pageDraftCached === null || pageDraftCached === void 0 ? void 0 : pageDraftCached.group) || !((_a = pageDraftCached === null || pageDraftCached === void 0 ? void 0 : pageDraftCached.group) === null || _a === void 0 ? void 0 : _a.settings))
            return;
        updatePageDraftInCache(__assign(__assign({}, pageDraftCached), { state: PublishState.AWAITING_UPDATE, group: __assign(__assign({}, pageDraftCached.group), { settings: __assign(__assign({}, pageDraftCached.group.settings), { allowAutoAccess: allowAutoAccess }) }) }));
    };
    var handleToggleDisplaySeats = function (allowDisplaySeats) {
        var _a;
        var pageDraftCached = getPageDraftFromCache();
        if (!(pageDraftCached === null || pageDraftCached === void 0 ? void 0 : pageDraftCached.group) || !((_a = pageDraftCached === null || pageDraftCached === void 0 ? void 0 : pageDraftCached.group) === null || _a === void 0 ? void 0 : _a.settings))
            return;
        updatePageDraftInCache(__assign(__assign({}, pageDraftCached), { state: PublishState.AWAITING_UPDATE, group: __assign(__assign({}, pageDraftCached.group), { settings: __assign(__assign({}, pageDraftCached.group.settings), { allowDisplaySeats: allowDisplaySeats }) }) }));
    };
    var handleToggleNewEntries = function (allowNewEntries) {
        var _a;
        var pageDraftCached = getPageDraftFromCache();
        if (!(pageDraftCached === null || pageDraftCached === void 0 ? void 0 : pageDraftCached.group) || !((_a = pageDraftCached === null || pageDraftCached === void 0 ? void 0 : pageDraftCached.group) === null || _a === void 0 ? void 0 : _a.settings))
            return;
        updatePageDraftInCache(__assign(__assign({}, pageDraftCached), { state: PublishState.AWAITING_UPDATE, group: __assign(__assign({}, pageDraftCached.group), { settings: __assign(__assign({}, pageDraftCached.group.settings), { allowNewEntries: allowNewEntries }) }) }));
    };
    var handleToggleTryForFree = function (allowTryForFree) {
        var _a;
        var pageDraftCached = getPageDraftFromCache();
        if (!(pageDraftCached === null || pageDraftCached === void 0 ? void 0 : pageDraftCached.group) || !((_a = pageDraftCached === null || pageDraftCached === void 0 ? void 0 : pageDraftCached.group) === null || _a === void 0 ? void 0 : _a.settings))
            return;
        updatePageDraftInCache(__assign(__assign({}, pageDraftCached), { state: PublishState.AWAITING_UPDATE, group: __assign(__assign({}, pageDraftCached.group), { settings: __assign(__assign({}, pageDraftCached.group.settings), { allowTryForFree: allowTryForFree }) }) }));
    };
    var handleToggleDisplayCalendar = function (allowDisplayCalendar) {
        var _a;
        var pageDraftCached = getPageDraftFromCache();
        if (!(pageDraftCached === null || pageDraftCached === void 0 ? void 0 : pageDraftCached.group) || !((_a = pageDraftCached === null || pageDraftCached === void 0 ? void 0 : pageDraftCached.group) === null || _a === void 0 ? void 0 : _a.settings))
            return;
        updatePageDraftInCache(__assign(__assign({}, pageDraftCached), { state: PublishState.AWAITING_UPDATE, group: __assign(__assign({}, pageDraftCached.group), { settings: __assign(__assign({}, pageDraftCached.group.settings), { allowDisplayCalendar: allowDisplayCalendar }) }) }));
    };
    var handleToggleProgramPreview = function (allowProgramPreview) {
        var _a;
        var pageDraftCached = getPageDraftFromCache();
        if (!(pageDraftCached === null || pageDraftCached === void 0 ? void 0 : pageDraftCached.group) || !((_a = pageDraftCached === null || pageDraftCached === void 0 ? void 0 : pageDraftCached.group) === null || _a === void 0 ? void 0 : _a.settings))
            return;
        updatePageDraftInCache(__assign(__assign({}, pageDraftCached), { state: PublishState.AWAITING_UPDATE, group: __assign(__assign({}, pageDraftCached.group), { settings: __assign(__assign({}, pageDraftCached.group.settings), { allowProgramPreview: allowProgramPreview }) }) }));
    };
    var handleUpdateDescription = function (outputData) {
        var pageDraftCached = getPageDraftFromCache();
        if (!pageDraftCached)
            return;
        if (isEqual(pageDraft.document.blocks, outputData.blocks))
            return;
        updatePageDraftInCache({
            state: PublishState.AWAITING_UPDATE,
            document: __assign(__assign({}, pageDraftCached.document), { content: outputData }),
        });
    };
    var handleUpdateBenefits = function (benefits) {
        updatePageDraftInCache({
            state: PublishState.AWAITING_UPDATE,
            benefits: benefits,
        });
    };
    return {
        loading: loading,
        getPageDraftFromCache: getPageDraftFromCache,
        updatePageDraftInCache: updatePageDraftInCache,
        handleUpdateDescription: handleUpdateDescription,
        handleUpdateBenefits: handleUpdateBenefits,
        handleUpdatePricePerSeat: handleUpdatePricePerSeat,
        handleUpdateCurrency: handleUpdateCurrency,
        handleUpdateMediaSrc: handleUpdateMediaSrc,
        handleUpdateCoverType: handleUpdateCoverType,
        handleUpdateShortDsc: handleUpdateShortDsc,
        handleToggleSubscription: handleToggleSubscription,
        handleUpdateMaxSeats: handleUpdateMaxSeats,
        handleToggleShowPrice: handleToggleShowPrice,
        handleToggleNewEntries: handleToggleNewEntries,
        handleToggleAutoAccess: handleToggleAutoAccess,
        handleToggleProgramPreview: handleToggleProgramPreview,
        handleToggleDisplaySeats: handleToggleDisplaySeats,
        handleToggleDisplayCalendar: handleToggleDisplayCalendar,
        handleToggleTryForFree: handleToggleTryForFree,
        handleUpdateProgramPreviewId: handleUpdateProgramPreviewId,
        handleUpdateMentorCalendarUrl: handleUpdateMentorCalendarUrl,
        handleUpdateEmailContactList: handleUpdateEmailContactList,
        handleUpdateCtaButtonType: handleUpdateCtaButtonType,
        pageDraft: loading ? initialValues : pageDraft,
    };
};
