"use client";
import { useQuery } from "@apollo/client";
import { FIND_EDITABLE_GROUP_PROGRAM_EVALUATIONS } from "../queries/findEditableProgramEvaluations";
export var useFindEditableGroupProgramEvaluations = function (groupId) {
    var _a = useQuery(FIND_EDITABLE_GROUP_PROGRAM_EVALUATIONS, {
        variables: { groupId: groupId },
        fetchPolicy: "cache-first",
    }), data = _a.data, error = _a.error, loading = _a.loading, refetch = _a.refetch;
    return {
        projectEvaluations: loading
            ? []
            : (data === null || data === void 0 ? void 0 : data.findEditableProgramEvaluations) || [],
        error: error,
        loading: loading,
        refetch: refetch,
    };
};
