"use client";
import { useQuery } from "@apollo/client";
import { FIND_MY_ACTIVE_GROUP_ACCESS } from "../queries/findMyActiveGroupAccess";
export var useFindMyActiveGroupAccess = function () {
    var _a = useQuery(FIND_MY_ACTIVE_GROUP_ACCESS, {
        fetchPolicy: "network-only",
    }), data = _a.data, error = _a.error, loading = _a.loading, refetch = _a.refetch;
    return {
        groupAccess: loading ? null : (data === null || data === void 0 ? void 0 : data.findMyActiveGroupAccess) || null,
        error: error,
        loading: loading,
        refetch: refetch,
    };
};
