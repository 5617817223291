"use client";
import { useQuery } from "@apollo/client";
import { GET_APP_REPOS } from "../queries/getAppRepos";
export var useGetAppRepositories = function () {
    var _a = useQuery(GET_APP_REPOS, { fetchPolicy: "network-only" }), data = _a.data, loading = _a.loading, error = _a.error, refetch = _a.refetch;
    return {
        repos: loading ? [] : (data === null || data === void 0 ? void 0 : data.githubAppRepositories) || [],
        error: error,
        refetch: refetch,
        loading: loading,
    };
};
