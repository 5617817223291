import { initialLessonSchedule } from "../lesson-schedule/constants";
export var initialLessonValues = {
    state: "",
    projectId: "",
    lessonScheduleId: "",
    lessonSchedule: initialLessonSchedule,
    isVisibleOnBoard: false,
    tasks: [],
    lessonTemplate: {},
    lessonTemplateId: "",
    id: "",
};
