"use client";
import { useMutation } from "@apollo/client";
import { UPDATE_PROJECT_TEMPLATES } from "../mutations/updateProjectTemplates";
import { FIND_MY_PROJECTS_TEMPLATES } from "../queries/findProjectTemplates";
export var useUpdateProjectTemplatesOrder = function () {
    var updateExistingProjectsOrder = useMutation(UPDATE_PROJECT_TEMPLATES)[0];
    var reorderProjectTemplates = function (projects) {
        var mappedProjectsToUpdate = projects.map(function (element) { return ({
            id: element.id,
            orderIndex: element.orderIndex,
        }); });
        updateExistingProjectsOrder({
            variables: { data: mappedProjectsToUpdate },
            optimisticResponse: {
                __typename: "Mutation",
                updateProjectTemplates: mappedProjectsToUpdate,
            },
            update: function (cache) {
                updateProjectTemplatesOrderInCache(cache, projects);
            },
        });
    };
    var updateProjectTemplatesOrderInCache = function (cache, projectTemplates) {
        cache.writeQuery({
            query: FIND_MY_PROJECTS_TEMPLATES,
            data: {
                findProjectTemplates: projectTemplates,
            },
        });
    };
    return {
        reorderProjectTemplates: reorderProjectTemplates,
    };
};
