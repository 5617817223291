"use client";
import { useQuery } from "@apollo/client";
import { initialProjectTemplate } from "../constants";
import { FIND_PUBLIC_PROJECT_TEMPLATE } from "../queries/findPublicProjectTemplate";
export var useFindPublicProjectTemplate = function (projectTemplateId) {
    var _a = useQuery(FIND_PUBLIC_PROJECT_TEMPLATE, {
        variables: { projectTemplateId: projectTemplateId },
        fetchPolicy: "cache-first",
    }), data = _a.data, loading = _a.loading, refetch = _a.refetch, error = _a.error;
    return {
        projectTemplate: loading
            ? initialProjectTemplate
            : (data === null || data === void 0 ? void 0 : data.findPublicProjectTemplate) || initialProjectTemplate,
        loading: loading,
        refetch: refetch,
        error: error,
    };
};
