"use client";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useQuery } from "@apollo/client";
import { initialCodeSolution } from "../constants";
import { FIND_TASK_CODE_SOLUTION } from "../queries/findTaskCodeSolution";
import { TASK_CODE_SOLUTION_UPDATED } from "../subscriptions/taskCodeSolutionUpdated";
export var useFindTaskCodeSolution = function (taskId) {
    var _a = useQuery(FIND_TASK_CODE_SOLUTION, {
        fetchPolicy: "network-only",
        variables: {
            taskId: taskId,
        },
    }), data = _a.data, loading = _a.loading, subscribeToMore = _a.subscribeToMore;
    var subscribeToCodeSolutionUpdate = function (taskId) {
        subscribeToMore({
            document: TASK_CODE_SOLUTION_UPDATED,
            variables: { taskId: taskId },
            updateQuery: function (prev, _a) {
                var subscriptionData = _a.subscriptionData;
                if (!subscriptionData.data)
                    return prev;
                var codeSolutionUpdated = subscriptionData.data.codeSolutionUpdated;
                return {
                    findTaskCodeSolution: __assign(__assign({}, prev.findTaskCodeSolution), { code: codeSolutionUpdated.code }),
                };
            },
        });
    };
    return {
        codeSolution: loading
            ? initialCodeSolution
            : ((data === null || data === void 0 ? void 0 : data.findTaskCodeSolution) && data.findTaskCodeSolution) ||
                initialCodeSolution,
        loading: loading,
        subscribeToCodeSolutionUpdate: subscribeToCodeSolutionUpdate,
    };
};
