import { useQuery } from "@apollo/client";
import { FIND_PUBLIC_GROUP_TEAMS } from "../queries/findPublicGroupTeams";
export var useFindPublicGroupTeams = function (groupId) {
    var _a = useQuery(FIND_PUBLIC_GROUP_TEAMS, {
        variables: { groupId: groupId },
    }), data = _a.data, loading = _a.loading, error = _a.error;
    return {
        teams: loading ? [] : (data === null || data === void 0 ? void 0 : data.findPublicGroupTeams) || [],
        loading: loading,
        error: error,
    };
};
