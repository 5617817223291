"use client";
import { useQuery } from "@apollo/client";
import { FIND_PROJECT_TEMPLATES_LIBRARY } from "../queries/findProjectTemplatesLibrary";
export var useFindProjectTemplatesLibrary = function () {
    var _a = useQuery(FIND_PROJECT_TEMPLATES_LIBRARY, {
        fetchPolicy: "cache-and-network", // Used for first execution
    }), data = _a.data, loading = _a.loading, refetch = _a.refetch;
    return {
        projectTemplates: loading ? [] : (data === null || data === void 0 ? void 0 : data.findProjectTemplatesLibrary) || [],
        loading: loading,
        refetch: refetch,
    };
};
