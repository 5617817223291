"use client";
import { useQuery } from "@apollo/client";
import { FIND_GROUP_AVAILABILITY } from "../queries/findGroupAvailability";
export var useFindGroupAvailability = function (groupId) {
    var _a = useQuery(FIND_GROUP_AVAILABILITY, {
        fetchPolicy: "cache-first",
        pollInterval: 30000,
        variables: { groupId: groupId },
    }), data = _a.data, error = _a.error, loading = _a.loading, refetch = _a.refetch;
    return {
        availability: loading ? null : (data === null || data === void 0 ? void 0 : data.findGroupAvailability) || null,
        error: error,
        loading: loading,
        refetch: refetch,
    };
};
