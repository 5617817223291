"use client";
import { useQuery } from "@apollo/client";
import { MONITOR_CONTENT_AMOUNT } from "../queries/countMyOrgContent";
var initialValues = {
    groups: 0,
    lessons: 0,
    projects: 0,
    tasks: 0,
};
export var useCountMyOrgContent = function () {
    var _a = useQuery(MONITOR_CONTENT_AMOUNT), data = _a.data, error = _a.error, loading = _a.loading, refetch = _a.refetch;
    return {
        content: loading
            ? initialValues
            : (data === null || data === void 0 ? void 0 : data.monitorContentAmount) || initialValues,
        error: error,
        loading: loading,
        refetch: refetch,
    };
};
