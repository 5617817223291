import { initialOrg } from "../organisation/constants";
import { Currency, GroupState, MediaType, PaymentMode, PublishState, } from "../types/graphql";
import { initialUserValues } from "../user/constants";
export var initialGroup = {
    paymentMode: PaymentMode.PAYMENT,
    updatedAt: "",
    id: "",
    name: "",
    mentorId: "",
    organisationId: "",
    currency: Currency.EUR,
    tags: [],
    pricePerSeat: 1,
    state: GroupState.inactive,
    program: {},
    mentor: initialUserValues,
    organisation: initialOrg,
    activatedAt: "",
    createdAt: "",
    accesses: [],
    settings: {
        id: "",
        groupId: "",
        maxSeats: 0,
        previewGroupId: "",
        mentorCalendarUrl: "",
        isPrimaryGroup: false,
        allowDisplayCalendar: false,
        allowTryForFree: false,
        allowAutoAccess: false,
        allowNewEntries: false,
        allowProgramPreview: false,
        allowDisplaySeats: false,
        viewInMarketplace: false,
    },
};
export var initialPageData = {
    benefits: [],
    coverImageSrc: "",
    currency: Currency.EUR,
    paymentMode: PaymentMode.PAYMENT,
    coverType: MediaType.IMAGE,
    programId: "",
    notionPageMap: null,
    notionPageId: null,
    showPrice: false,
    ctaButtonType: "",
    contactListId: null,
    document: {
        blocks: [],
    },
    coverMediaSrc: "",
    documentId: "",
    groupId: "",
    groupName: "",
    groupState: GroupState.inactive,
    id: "",
    maxSeats: 0,
    pricePerSeat: 0,
    shortDsc: "",
    slug: "",
    state: PublishState.DRAFT,
    allowAutoAccess: false,
    allowNewEntries: false,
    allowTryForFree: false,
    allowDisplayCalendar: false,
    mentorCalendarUrl: "",
    previewGroupId: "",
    allowProgramPreview: false,
    allowDisplaySeats: false,
    usersAmount: 0,
};
