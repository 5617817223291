"use client";
import { useQuery } from "@apollo/client";
import { FIND_MY_ORG_GROUPS } from "../queries/findMyOrgGroups";
export var useFindMyOrgGroups = function () {
    var _a = useQuery(FIND_MY_ORG_GROUPS, {
        fetchPolicy: "cache-and-network",
    }), data = _a.data, error = _a.error, loading = _a.loading, refetch = _a.refetch;
    return {
        groups: loading ? [] : (data === null || data === void 0 ? void 0 : data.findMyOrgGroups) || [],
        error: error,
        loading: loading,
        refetch: refetch,
    };
};
