"use client";
import { useQuery } from "@apollo/client";
import { FIND_PUBLIC_PROJECT_LESSON_TEMPLATES } from "../queries/findPublicProjectLessonTemplates";
export var useFindPublicProjectLessonTemplates = function (projectTemplateId) {
    var _a = useQuery(FIND_PUBLIC_PROJECT_LESSON_TEMPLATES, {
        variables: { projectTemplateId: projectTemplateId },
        fetchPolicy: "cache-first", // Used for first execution
    }), data = _a.data, loading = _a.loading, refetch = _a.refetch, error = _a.error;
    return {
        lessonTemplates: loading
            ? []
            : (data === null || data === void 0 ? void 0 : data.findPublicProjectLessonTemplates) || [],
        loading: loading,
        refetch: refetch,
        error: error,
    };
};
