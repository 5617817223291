/*
 * -------------------------------------------------------
 * THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 * -------------------------------------------------------
 */
/* tslint:disable */
/* eslint-disable */
export var AccessPlan;
(function (AccessPlan) {
    AccessPlan["FREE"] = "FREE";
    AccessPlan["NERDBORDER"] = "NERDBORDER";
    AccessPlan["NERDBORDER_PRO"] = "NERDBORDER_PRO";
})(AccessPlan || (AccessPlan = {}));
export var AccessRequestStatus;
(function (AccessRequestStatus) {
    AccessRequestStatus["APPROVED"] = "APPROVED";
    AccessRequestStatus["EXPIRED"] = "EXPIRED";
    AccessRequestStatus["PENDING"] = "PENDING";
    AccessRequestStatus["REVOKED"] = "REVOKED";
})(AccessRequestStatus || (AccessRequestStatus = {}));
export var CodeLanguage;
(function (CodeLanguage) {
    CodeLanguage["CPP"] = "CPP";
    CodeLanguage["JAVA"] = "JAVA";
    CodeLanguage["JAVASCRIPT"] = "JAVASCRIPT";
    CodeLanguage["MYSQL"] = "MYSQL";
    CodeLanguage["PHP"] = "PHP";
    CodeLanguage["PYTHON"] = "PYTHON";
    CodeLanguage["RUBY"] = "RUBY";
    CodeLanguage["RUST"] = "RUST";
    CodeLanguage["TYPESCRIPT"] = "TYPESCRIPT";
})(CodeLanguage || (CodeLanguage = {}));
export var CoinTransactionChannel;
(function (CoinTransactionChannel) {
    CoinTransactionChannel["ADMIN"] = "ADMIN";
    CoinTransactionChannel["BONUS_POINTS"] = "BONUS_POINTS";
    CoinTransactionChannel["LEVEL_UP"] = "LEVEL_UP";
    CoinTransactionChannel["REFERRAL"] = "REFERRAL";
    CoinTransactionChannel["REVIEW_REQUEST"] = "REVIEW_REQUEST";
    CoinTransactionChannel["SHOP"] = "SHOP";
    CoinTransactionChannel["SIGN_UP"] = "SIGN_UP";
})(CoinTransactionChannel || (CoinTransactionChannel = {}));
export var CollaborationType;
(function (CollaborationType) {
    CollaborationType["COLLABORATION"] = "COLLABORATION";
    CollaborationType["INDIVIDUAL"] = "INDIVIDUAL";
})(CollaborationType || (CollaborationType = {}));
export var ContentState;
(function (ContentState) {
    ContentState["ACTIVE"] = "ACTIVE";
    ContentState["COMPLETED"] = "COMPLETED";
    ContentState["FREEZED"] = "FREEZED";
    ContentState["INACTIVE"] = "INACTIVE";
    ContentState["UPDATE_AVAILABLE"] = "UPDATE_AVAILABLE";
})(ContentState || (ContentState = {}));
export var Currency;
(function (Currency) {
    Currency["EUR"] = "EUR";
    Currency["PLN"] = "PLN";
    Currency["USD"] = "USD";
})(Currency || (Currency = {}));
export var DifficultyLevel;
(function (DifficultyLevel) {
    DifficultyLevel["ELITE"] = "ELITE";
    DifficultyLevel["INTRO"] = "INTRO";
    DifficultyLevel["PRO"] = "PRO";
})(DifficultyLevel || (DifficultyLevel = {}));
export var ExperienceLevel;
(function (ExperienceLevel) {
    ExperienceLevel["INTERN"] = "INTERN";
    ExperienceLevel["JUNIOR"] = "JUNIOR";
    ExperienceLevel["MID"] = "MID";
    ExperienceLevel["SENIOR_PLUS"] = "SENIOR_PLUS";
})(ExperienceLevel || (ExperienceLevel = {}));
export var GithubRepoDistribution;
(function (GithubRepoDistribution) {
    GithubRepoDistribution["FORK"] = "FORK";
    GithubRepoDistribution["TEMPLATE"] = "TEMPLATE";
})(GithubRepoDistribution || (GithubRepoDistribution = {}));
export var GroupPageButtonType;
(function (GroupPageButtonType) {
    GroupPageButtonType["JOIN_WITH_EMAIL"] = "JOIN_WITH_EMAIL";
    GroupPageButtonType["JOIN_WITH_GITHUB"] = "JOIN_WITH_GITHUB";
    GroupPageButtonType["SUBMIT_REQUEST"] = "SUBMIT_REQUEST";
})(GroupPageButtonType || (GroupPageButtonType = {}));
export var GroupState;
(function (GroupState) {
    GroupState["active"] = "active";
    GroupState["archived"] = "archived";
    GroupState["freezed"] = "freezed";
    GroupState["inactive"] = "inactive";
})(GroupState || (GroupState = {}));
export var MediaType;
(function (MediaType) {
    MediaType["EMBED"] = "EMBED";
    MediaType["IMAGE"] = "IMAGE";
    MediaType["VIDEO"] = "VIDEO";
})(MediaType || (MediaType = {}));
export var PaymentMode;
(function (PaymentMode) {
    PaymentMode["PAYMENT"] = "PAYMENT";
    PaymentMode["SUBSCRIPTION"] = "SUBSCRIPTION";
})(PaymentMode || (PaymentMode = {}));
export var ProgressState;
(function (ProgressState) {
    ProgressState["BACKLOG"] = "BACKLOG";
    ProgressState["DONE"] = "DONE";
    ProgressState["IN_PROGRESS"] = "IN_PROGRESS";
    ProgressState["IN_REVIEW"] = "IN_REVIEW";
})(ProgressState || (ProgressState = {}));
export var ProjectType;
(function (ProjectType) {
    ProjectType["GIT_FLOW"] = "GIT_FLOW";
    ProjectType["SANDBOX"] = "SANDBOX";
})(ProjectType || (ProjectType = {}));
export var PublishState;
(function (PublishState) {
    PublishState["AWAITING_UPDATE"] = "AWAITING_UPDATE";
    PublishState["DRAFT"] = "DRAFT";
    PublishState["PUBLISHED"] = "PUBLISHED";
})(PublishState || (PublishState = {}));
export var Rank;
(function (Rank) {
    Rank["BIT_LORD"] = "BIT_LORD";
    Rank["CURIOUS_EXPLORER"] = "CURIOUS_EXPLORER";
    Rank["CYBERPUNK"] = "CYBERPUNK";
    Rank["ITERATOR_3000"] = "ITERATOR_3000";
    Rank["NOVICE_ADVENTURER"] = "NOVICE_ADVENTURER";
    Rank["PROBLEM_INVESTIGATOR"] = "PROBLEM_INVESTIGATOR";
    Rank["PROGRAM_CONQUEROR"] = "PROGRAM_CONQUEROR";
    Rank["SKILLED_APPRENTICE"] = "SKILLED_APPRENTICE";
    Rank["TASKMASTER"] = "TASKMASTER";
    Rank["TECH_ELDER"] = "TECH_ELDER";
    Rank["WEB_CRAWLER"] = "WEB_CRAWLER";
})(Rank || (Rank = {}));
export var Role;
(function (Role) {
    Role["ADMIN"] = "ADMIN";
    Role["APP"] = "APP";
    Role["MENTOR"] = "MENTOR";
    Role["ORG_MEMBER"] = "ORG_MEMBER";
    Role["ORG_OWNER"] = "ORG_OWNER";
    Role["STUDENT"] = "STUDENT";
    Role["USER"] = "USER";
})(Role || (Role = {}));
export var SchedulingState;
(function (SchedulingState) {
    SchedulingState["HIDDEN"] = "HIDDEN";
    SchedulingState["LIVE"] = "LIVE";
    SchedulingState["LOCKED"] = "LOCKED";
    SchedulingState["SCHEDULED"] = "SCHEDULED";
})(SchedulingState || (SchedulingState = {}));
export var SubscriptionPlan;
(function (SubscriptionPlan) {
    SubscriptionPlan["MENTOR"] = "MENTOR";
    SubscriptionPlan["PERSONAL"] = "PERSONAL";
})(SubscriptionPlan || (SubscriptionPlan = {}));
export var SubscriptionStatus;
(function (SubscriptionStatus) {
    SubscriptionStatus["active"] = "active";
    SubscriptionStatus["canceled"] = "canceled";
    SubscriptionStatus["incomplete"] = "incomplete";
    SubscriptionStatus["incomplete_expired"] = "incomplete_expired";
    SubscriptionStatus["past_due"] = "past_due";
    SubscriptionStatus["trialing"] = "trialing";
    SubscriptionStatus["unpaid"] = "unpaid";
})(SubscriptionStatus || (SubscriptionStatus = {}));
export var TaskReviewState;
(function (TaskReviewState) {
    TaskReviewState["APPROVED"] = "APPROVED";
    TaskReviewState["APPROVED_BY_MENTOR"] = "APPROVED_BY_MENTOR";
    TaskReviewState["NO_REVIEW_REQUESTED"] = "NO_REVIEW_REQUESTED";
    TaskReviewState["REJECTED"] = "REJECTED";
    TaskReviewState["REVIEW_COMPLETED"] = "REVIEW_COMPLETED";
    TaskReviewState["REVIEW_REQUESTED"] = "REVIEW_REQUESTED";
})(TaskReviewState || (TaskReviewState = {}));
export var TaskType;
(function (TaskType) {
    TaskType["CODE_EDITOR"] = "CODE_EDITOR";
    TaskType["GIT"] = "GIT";
    TaskType["TEXT_EDITOR"] = "TEXT_EDITOR";
    TaskType["THEORY"] = "THEORY";
})(TaskType || (TaskType = {}));
export var Theme;
(function (Theme) {
    Theme["AMBER"] = "AMBER";
    Theme["BLUE"] = "BLUE";
    Theme["BLUE_GRAY"] = "BLUE_GRAY";
    Theme["CARDBOARD"] = "CARDBOARD";
    Theme["CONVERTO"] = "CONVERTO";
    Theme["CYAN"] = "CYAN";
    Theme["DEFAULT"] = "DEFAULT";
    Theme["EMERALD"] = "EMERALD";
    Theme["FUCHSIA"] = "FUCHSIA";
    Theme["GREEN"] = "GREEN";
    Theme["INDIGO"] = "INDIGO";
    Theme["LAB"] = "LAB";
    Theme["LIGHT_BLUE"] = "LIGHT_BLUE";
    Theme["LIME"] = "LIME";
    Theme["ORANGE"] = "ORANGE";
    Theme["PINK"] = "PINK";
    Theme["PURPLE"] = "PURPLE";
    Theme["RED"] = "RED";
    Theme["ROSE"] = "ROSE";
    Theme["TEAL"] = "TEAL";
    Theme["VIOLET"] = "VIOLET";
    Theme["YELLOW"] = "YELLOW";
})(Theme || (Theme = {}));
export var TransactionType;
(function (TransactionType) {
    TransactionType["INCOME"] = "INCOME";
    TransactionType["OUTCOME"] = "OUTCOME";
})(TransactionType || (TransactionType = {}));
export var UserSpecialization;
(function (UserSpecialization) {
    UserSpecialization["BACKEND"] = "BACKEND";
    UserSpecialization["DESIGNER"] = "DESIGNER";
    UserSpecialization["FRONTEND"] = "FRONTEND";
    UserSpecialization["FULLSTACK"] = "FULLSTACK";
})(UserSpecialization || (UserSpecialization = {}));
