"use client";
import { useQuery } from "@apollo/client";
import { initialBoardValues } from "../constants";
import { FIND_MY_BOARD } from "../queries/findMyBoard";
export var useFindMyBoard = function () {
    var _a = useQuery(FIND_MY_BOARD), data = _a.data, error = _a.error, loading = _a.loading, refetch = _a.refetch;
    return {
        board: loading
            ? initialBoardValues
            : (data === null || data === void 0 ? void 0 : data.findMyBoard) || initialBoardValues,
        error: error,
        loading: loading,
        refetch: refetch,
    };
};
