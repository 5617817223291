import { initialDocumentValues } from "../document/constants";
import { initialLessonTemplateValues } from "../lesson-template/constants";
export var initialPullCondition = {
    id: "",
    isRequired: false,
    isMergeRequired: false,
    isOn: false,
    taskTemplateId: "",
};
export var initialReviewCondition = {
    price: 0,
    id: "",
    isRequired: false,
    enableAuto: false,
    enableManual: false,
    isOn: false,
    isDeploymentRequired: false,
    taskTemplateId: "",
};
export var initialCodeSolutionTemplate = {
    code: "",
    id: "",
    language: " ",
    taskTemplateId: "",
};
export var initialTextSolutionTemplate = {
    id: "",
    templateId: "",
    taskTemplateId: "",
};
export var defaultTaskTemplate = {
    id: "",
    title: "",
    brief: initialDocumentValues,
    tags: [],
    briefId: "",
    lessonTemplateId: "",
    lessonTemplate: initialLessonTemplateValues,
    type: "",
    isPublished: false,
    orderIndex: 0,
    pullRequestCondition: initialPullCondition,
    reviewCondition: initialReviewCondition,
    createdAt: "",
};
