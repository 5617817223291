"use client";
import { useQuery } from "@apollo/client";
import { CHECK_IF_TASK_IS_COLLABORATIVE } from "../queries/checkIfTaskIsCollaborative";
export var useCheckIfTaskIsCollaborative = function (taskId) {
    var _a = useQuery(CHECK_IF_TASK_IS_COLLABORATIVE, {
        variables: { taskId: taskId },
    }), data = _a.data, error = _a.error, loading = _a.loading, refetch = _a.refetch;
    return {
        isTaskCollaborative: loading
            ? false
            : (data === null || data === void 0 ? void 0 : data.checkIfTaskIsCollaborative) || false,
        error: error,
        loading: loading,
        refetch: refetch,
    };
};
