"use client";
import { useQuery } from "@apollo/client";
import { mapTaskEntityToTask } from "../mappers";
import { initialTaskEntity } from "../constants";
import { FIND_MY_TASK } from "../queries/findMyTask";
export var useFindMyTask = function (taskId) {
    var _a = useQuery(FIND_MY_TASK, {
        variables: {
            taskId: taskId,
        },
        fetchPolicy: "cache-first",
    }), data = _a.data, loading = _a.loading;
    var initial = mapTaskEntityToTask(initialTaskEntity);
    return {
        task: loading
            ? initial
            : ((data === null || data === void 0 ? void 0 : data.findMyTask) && mapTaskEntityToTask(data.findMyTask)) || initial,
        loading: loading,
    };
};
