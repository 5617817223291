"use client";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useQuery } from "@apollo/client";
import { GET_GITHUB_CREDENTIALS } from "../queries/getGithubCredentials";
import { GITHUB_CREDENTIALS_UPDATED } from "../subscriptions/githubCredentialsUpdated";
var initialCredentials = {
    createdAt: "",
    id: "",
    username: "",
    userId: "",
};
export var useGithubCredentials = function (userId) {
    var _a = useQuery(GET_GITHUB_CREDENTIALS, {
        variables: { userId: userId },
    }), data = _a.data, loading = _a.loading, error = _a.error, refetch = _a.refetch, subscribeToMore = _a.subscribeToMore;
    var subscribeToGithubCredentials = function () {
        subscribeToMore({
            document: GITHUB_CREDENTIALS_UPDATED,
            variables: { userId: userId },
            updateQuery: function (prev, _a) {
                var subscriptionData = _a.subscriptionData;
                if (!subscriptionData.data)
                    return prev;
                var githubUserCredentialsUpdated = subscriptionData.data.githubUserCredentialsUpdated;
                return {
                    githubCredentials: __assign(__assign({}, prev.githubCredentials), githubUserCredentialsUpdated),
                };
            },
        });
    };
    return {
        githubCredentials: loading
            ? initialCredentials
            : (data === null || data === void 0 ? void 0 : data.githubCredentials) || initialCredentials,
        loading: loading,
        refetch: refetch,
        error: error,
        subscribeToGithubCredentials: subscribeToGithubCredentials,
    };
};
