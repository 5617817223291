"use client";
import { useQuery } from "@apollo/client";
import { initialTextSolutionTemplate } from "../constants";
import { FIND_TEXT_SOLUTION_TEMPLATE } from "../queries/findTextSolutionTemplate";
export var useFindTextSolutionTemplate = function (taskTemplateId) {
    var _a = useQuery(FIND_TEXT_SOLUTION_TEMPLATE, {
        variables: {
            taskTemplateId: taskTemplateId,
        },
        fetchPolicy: "network-only",
    }), data = _a.data, loading = _a.loading;
    return {
        textSolutionTemplate: loading
            ? initialTextSolutionTemplate
            : (data === null || data === void 0 ? void 0 : data.findTextSolutionTemplate) || initialTextSolutionTemplate,
        loading: loading,
    };
};
