"use client";
import { useQuery } from "@apollo/client";
import { initialLessonSchedule } from "../constants";
import { FIND_LESSON_SCHEDULE } from "../queries/findLessonSchedule";
export var useFindLessonSchedule = function (id) {
    var _a = useQuery(FIND_LESSON_SCHEDULE, {
        variables: { id: id },
        fetchPolicy: "cache-first",
    }), data = _a.data, loading = _a.loading, refetch = _a.refetch, error = _a.error;
    return {
        lessonSchedule: loading
            ? initialLessonSchedule
            : (data === null || data === void 0 ? void 0 : data.findLessonSchedule) || initialLessonSchedule,
        loading: loading,
        refetch: refetch,
        error: error,
    };
};
