"use client";
import { useQuery } from "@apollo/client";
import { initialTaskReview } from "../constants";
import { FIND_TASK_REVIEW } from "../queries/findTaskReview";
import { TASK_REVIEW_UPDATED } from "../subscriptions/taskReviewUpdated";
export var useFindTaskReview = function (taskId) {
    var _a = useQuery(FIND_TASK_REVIEW, {
        variables: {
            taskId: taskId,
        },
    }), data = _a.data, loading = _a.loading, subscribeToMore = _a.subscribeToMore, cache = _a.client.cache;
    var subscribeToTaskReviewUpdate = function () {
        subscribeToMore({
            document: TASK_REVIEW_UPDATED,
            variables: { taskId: taskId },
            updateQuery: function (prev, _a) {
                var subscriptionData = _a.subscriptionData;
                if (!subscriptionData.data)
                    return prev;
                var taskReviewUpdated = subscriptionData.data.taskReviewUpdated;
                updateTaskReviewInCache(cache, taskReviewUpdated.id, taskReviewUpdated.reviewState);
                return {
                    findTaskReview: taskReviewUpdated,
                };
            },
        });
    };
    var updateTaskReviewInCache = function (cache, taskReviewId, state) {
        var reviewToUpdate = {
            id: taskReviewId,
            __typename: "TaskReviewEntity",
        };
        cache.modify({
            id: cache.identify(reviewToUpdate),
            fields: {
                reviewState: function () {
                    return state;
                },
            },
        });
    };
    return {
        taskReview: loading
            ? initialTaskReview
            : (data === null || data === void 0 ? void 0 : data.findTaskReview) || initialTaskReview,
        subscribeToTaskReviewUpdate: subscribeToTaskReviewUpdate,
        loading: loading,
    };
};
