"use client";
import { useQuery } from "@apollo/client";
import { mapTaskEntityToTask } from "../mappers";
import { initialTaskEntity } from "../constants";
import { FIND_MY_STUDENT_TASK } from "../queries/findMyStudentTask";
export var useFindMyStudentTask = function (taskId) {
    var _a = useQuery(FIND_MY_STUDENT_TASK, {
        variables: {
            taskId: taskId,
        },
    }), data = _a.data, loading = _a.loading;
    var initial = mapTaskEntityToTask(initialTaskEntity);
    return {
        task: loading
            ? initial
            : ((data === null || data === void 0 ? void 0 : data.findMyStudentTask) &&
                mapTaskEntityToTask(data.findMyStudentTask)) ||
                initial,
        loading: loading,
    };
};
