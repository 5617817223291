export var mapProjectWithProgressToProject = function (_a) {
    var _b, _c, _d, _e, _f;
    var progress = _a.progress, project = _a.project;
    return {
        id: project.id,
        projectTemplateId: project.projectTemplate.id,
        projectScheduleId: project.projectScheduleId,
        name: project.projectTemplate.name,
        subtitle: ((_b = project.projectTemplate) === null || _b === void 0 ? void 0 : _b.subtitle) || null,
        repositoryOwner: project.repositoryOwner || null,
        repositoryName: project.repositoryName || null,
        teamId: project.teamId || null,
        type: project.projectTemplate.type,
        theme: project.projectTemplate.theme,
        showInPortfolio: project.showInPortfolio,
        deploymentUrl: (project === null || project === void 0 ? void 0 : project.deploymentUrl) || null,
        briefId: project.projectTemplate.briefId,
        state: project.state,
        eta: project.projectTemplate.eta,
        isPublic: (_c = project === null || project === void 0 ? void 0 : project.projectSchedule) === null || _c === void 0 ? void 0 : _c.isPublic,
        difficultyLevel: project.projectTemplate.difficultyLevel,
        scheduledLessons: ((_d = project === null || project === void 0 ? void 0 : project.projectSchedule) === null || _d === void 0 ? void 0 : _d.lessonSchedules) || [],
        tags: !!((_f = (_e = project.projectTemplate) === null || _e === void 0 ? void 0 : _e.tags) === null || _f === void 0 ? void 0 : _f.length)
            ? project.projectTemplate.tags.map(function (tag) { return tag.name; })
            : [],
        completedTasks: progress.completedTasksAmount,
        scheduledTasks: progress.scheduledTasksAmount,
    };
};
