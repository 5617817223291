"use client";
import { useQuery } from "@apollo/client";
import { CHECK_IF_USER_HAS_GROUP_ACCESS } from "../queries/checkIfUserHasGroupAccess";
export var useCheckUserGroupAccess = function (groupId) {
    var _a = useQuery(CHECK_IF_USER_HAS_GROUP_ACCESS, {
        variables: { groupId: groupId },
    }), data = _a.data, error = _a.error, loading = _a.loading, refetch = _a.refetch;
    return {
        hasAccess: loading ? false : (data === null || data === void 0 ? void 0 : data.checkIfUserHasGroupAccess) || false,
        error: error,
        loading: loading,
        refetch: refetch,
    };
};
