"use client";
import { useQuery } from "@apollo/client";
import { mapProjectWithProgressToProject, } from "../..";
import { FIND_MY_PROJECTS_WITH_PROGRESS } from "../queries/findMyProjectsWithProgress";
export var useFindMyProjects = function () {
    var _a = useQuery(FIND_MY_PROJECTS_WITH_PROGRESS, {
        fetchPolicy: "network-only",
    }), data = _a.data, error = _a.error, loading = _a.loading, refetch = _a.refetch;
    return {
        projects: loading
            ? []
            : (data === null || data === void 0 ? void 0 : data.findMyProjects.map(mapProjectWithProgressToProject)) || [],
        error: error,
        loading: loading,
        refetch: refetch,
    };
};
