"use client";
import { useQuery } from "@apollo/client";
import { FIND_GROUPS_ACCESS_REQUESTS } from "../queries/findGroupAccessRequests";
export var useFindGroupAccessRequests = function (groupId) {
    var _a = useQuery(FIND_GROUPS_ACCESS_REQUESTS, {
        fetchPolicy: "cache-first",
        pollInterval: 30000,
        variables: { groupId: groupId },
    }), data = _a.data, error = _a.error, loading = _a.loading, refetch = _a.refetch, fetchMore = _a.fetchMore;
    return {
        accesses: loading ? [] : (data === null || data === void 0 ? void 0 : data.findGroupAccessRequests) || [],
        error: error,
        loading: loading,
        refetch: refetch,
        fetchMore: fetchMore,
    };
};
