"use client";
import { useQuery } from "@apollo/client";
import { FIND_MY_PAYMENT_CARDS } from "../queries/findMyPaymentCards";
export var useFindMyPaymentCards = function () {
    var _a = useQuery(FIND_MY_PAYMENT_CARDS, {
        fetchPolicy: "cache-first",
    }), data = _a.data, loading = _a.loading;
    return {
        cards: loading ? [] : (data === null || data === void 0 ? void 0 : data.findMyPaymentCards) || [],
        loading: loading,
    };
};
