"use client";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useQuery } from "@apollo/client";
import { GET_TASK_GITHUB_DETAILS } from "../queries/getTaskGithubDetails";
import { GITHUB_TASK_DETAILS_UPDATED } from "../subscriptions/githubTaskDetailsUpdated";
var initialData = {
    id: "",
    taskId: "",
    merged: false,
    repositoryName: "",
    mentorReviewers: [],
};
export var useGetTaskGithubDetails = function (taskId) {
    var _a = useQuery(GET_TASK_GITHUB_DETAILS, {
        variables: { taskId: taskId },
        fetchPolicy: "network-only",
    }), data = _a.data, loading = _a.loading, error = _a.error, subscribeToMore = _a.subscribeToMore;
    var subscribeToGithubTaskDetails = function () {
        subscribeToMore({
            document: GITHUB_TASK_DETAILS_UPDATED,
            variables: { taskId: taskId },
            updateQuery: function (prev, _a) {
                var subscriptionData = _a.subscriptionData;
                if (!subscriptionData.data)
                    return prev;
                var githubDetailsUpdated = subscriptionData.data.githubDetailsUpdated;
                return {
                    githubTaskDetails: __assign(__assign({}, prev.githubTaskDetails), githubDetailsUpdated),
                };
            },
        });
    };
    return {
        githubTaskDetails: loading
            ? initialData
            : (data === null || data === void 0 ? void 0 : data.githubTaskDetails) || initialData,
        loading: loading,
        error: error,
        subscribeToGithubTaskDetails: subscribeToGithubTaskDetails,
    };
};
