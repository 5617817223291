"use client";
import { useQuery } from "@apollo/client";
import { FIND_NERDBORD_SUBSCRIPTIONS } from "../queries/findNerdbordProducts";
export var useFindPricingPlans = function () {
    var _a = useQuery(FIND_NERDBORD_SUBSCRIPTIONS, { fetchPolicy: "cache-first" }), data = _a.data, loading = _a.loading;
    return {
        plans: loading ? [] : (data === null || data === void 0 ? void 0 : data.findSubscriptionsPricing) || [],
        loading: loading,
    };
};
