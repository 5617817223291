"use client";
import { useQuery } from "@apollo/client";
import { mapTaskEntityToTask } from "../mappers";
import { FIND_MY_STUDENT_TASKS } from "../queries/findMyStudentTasks";
export var useFindMyStudentTasks = function (userId, programId) {
    var _a = useQuery(FIND_MY_STUDENT_TASKS, {
        fetchPolicy: "network-only",
        variables: {
            userId: userId,
            programId: programId,
        },
    }), data = _a.data, loading = _a.loading;
    return {
        tasks: loading
            ? []
            : (data === null || data === void 0 ? void 0 : data.findMyStudentTasks.map(function (taskDto) {
                return mapTaskEntityToTask(taskDto);
            })) || [],
        loading: loading,
    };
};
