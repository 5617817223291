"use client";
import { useQuery } from "@apollo/client";
import { defaultTaskTemplate } from "../constants";
import { FIND_PUBLIC_TASK_TEMPLATE } from "../queries/findPublicTaskTemplate";
export var useFindPublicTaskTemplate = function (taskTemplateId) {
    var _a = useQuery(FIND_PUBLIC_TASK_TEMPLATE, {
        fetchPolicy: "cache-first",
        variables: {
            taskTemplateId: taskTemplateId,
        },
    }), data = _a.data, loading = _a.loading, refetch = _a.refetch, error = _a.error;
    return {
        taskTemplate: loading
            ? defaultTaskTemplate
            : (data === null || data === void 0 ? void 0 : data.findPublicTaskTemplate) || defaultTaskTemplate,
        loading: loading,
        refetch: refetch,
        error: error,
    };
};
