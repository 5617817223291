import { useQuery } from "@apollo/client";
import { FIND_GROUP_DISCORD_SETTINGS } from "../queries/findGroupDiscordSettings";
/**
 * Hook to fetch the Discord settings of a group.
 *
 * @param {string} groupId - The ID of the group.
 * @returns {Object} An object containing the following properties:
 * - discordSettings: The Discord settings of the group. If the data is still loading, this will be null.
 * - error: Any error that occurred while fetching the data.
 * - loading: A boolean indicating whether the data is still loading.
 * - refetch: A function to refetch the data.
 *
 * @example
 * const { discordSettings, error, loading, refetch } = useFindGroupDiscordSettings('groupId123');
 */
export var useFindGroupDiscordSettings = function (groupId) {
    var _a = useQuery(FIND_GROUP_DISCORD_SETTINGS, {
        variables: { groupId: groupId },
        fetchPolicy: "cache-first",
    }), data = _a.data, error = _a.error, loading = _a.loading, refetch = _a.refetch;
    return {
        discordSettings: loading ? null : (data === null || data === void 0 ? void 0 : data.findGroupDiscordSettings) || null,
        error: error,
        loading: loading,
        refetch: refetch,
    };
};
