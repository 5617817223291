import { initialRating } from "../rating/constants";
export var initialUserValues = {
    id: "",
    username: "",
    groups: [],
    email: "",
    roles: [],
    avatarUrl: "",
    bio: "",
    isBlocked: false,
    isActive: false,
    nerdcoins: 0,
    organisationId: "",
    rating: initialRating,
    isOnboardingComplete: false,
    createdAt: "",
    lastSeenAt: "",
};
