"use client";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useQuery } from "@apollo/client";
import { FIND_GROUP_TASK_REVIEWS } from "../queries/findGroupTaskReviews";
import { TASK_REVIEW_REQUESTED_IN_GROUP } from "../subscriptions/reviewRequestedInGroup";
export var useFindGroupTaskReviews = function (groupId, state) {
    var _a = useQuery(FIND_GROUP_TASK_REVIEWS, {
        fetchPolicy: "network-only",
        variables: { groupId: groupId, state: state },
    }), data = _a.data, loading = _a.loading, subscribeToMore = _a.subscribeToMore;
    var subscribeToNewReviews = function (groupId) {
        subscribeToMore({
            document: TASK_REVIEW_REQUESTED_IN_GROUP,
            variables: { groupId: groupId },
            updateQuery: function (prev, _a) {
                var subscriptionData = _a.subscriptionData;
                if (!subscriptionData.data)
                    return prev;
                var reviewRequestedInGroup = subscriptionData.data.reviewRequestedInGroup;
                return {
                    findGroupTaskReviews: __spreadArray(__spreadArray([], prev.findGroupTaskReviews, true), [
                        reviewRequestedInGroup,
                    ], false),
                };
            },
        });
    };
    return {
        reviews: loading ? [] : (data === null || data === void 0 ? void 0 : data.findGroupTaskReviews) || [],
        loading: loading,
        subscribeToNewReviews: subscribeToNewReviews,
    };
};
