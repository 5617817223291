"use client";
import { useQuery } from "@apollo/client";
import { mapTaskEntityToTask } from "../mappers";
import { FIND_PROJECT_TASKS } from "../queries/findProjectTasks";
export var useFindProjectTasks = function (projectId) {
    var _a = useQuery(FIND_PROJECT_TASKS, {
        fetchPolicy: "network-only",
        variables: {
            projectId: projectId,
        },
    }), data = _a.data, loading = _a.loading;
    return {
        tasks: loading
            ? []
            : (data === null || data === void 0 ? void 0 : data.findProjectTasks.map(function (taskDto) {
                return mapTaskEntityToTask(taskDto);
            })) || [],
        loading: loading,
    };
};
