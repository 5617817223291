import { ProjectType, Theme, } from "../types/graphql";
export var initialProjectValue = {
    completedTasks: 0,
    id: "",
    name: "",
    teamId: null,
    projectScheduleId: "",
    repositoryOwner: "",
    repositoryName: "",
    projectTemplateId: "",
    briefId: "",
    showInPortfolio: false,
    deploymentUrl: null,
    scheduledLessons: [],
    subtitle: null,
    isPublic: false,
    scheduledTasks: 0,
    eta: 0,
    state: "",
    difficultyLevel: "",
    tags: [],
    theme: Theme.DEFAULT,
    type: ProjectType.SANDBOX,
};
