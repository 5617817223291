"use client";
import { useQuery } from "@apollo/client";
import { initialLessonTemplateValues } from "../constants";
import { FIND_LESSON_TEMPLATE } from "../queries/findLessonTemplate";
export var useFindOwnedLessonTemplate = function (lessonTemplateId) {
    var _a = useQuery(FIND_LESSON_TEMPLATE, {
        variables: { id: lessonTemplateId },
        fetchPolicy: "cache-first",
    }), data = _a.data, loading = _a.loading, refetch = _a.refetch, error = _a.error;
    return {
        lessonTemplate: loading
            ? initialLessonTemplateValues
            : (data === null || data === void 0 ? void 0 : data.findLessonTemplate) || initialLessonTemplateValues,
        loading: loading,
        refetch: refetch,
        error: error,
    };
};
