"use client";
import { useQuery } from "@apollo/client";
import { FIND_CONTACTS_LIST } from "../queries/findEmailAutomationContactLists";
export var useFindPageContactLists = function () {
    var _a = useQuery(FIND_CONTACTS_LIST, {
        fetchPolicy: "cache-first",
    }), data = _a.data, error = _a.error, loading = _a.loading, refetch = _a.refetch;
    return {
        lists: loading ? [] : (data === null || data === void 0 ? void 0 : data.findEmailAutomationContactLists) || [],
        error: error,
        loading: loading,
        refetch: refetch,
    };
};
