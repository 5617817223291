export var initialSubscription = {
    createdAt: undefined,
    id: "",
    organisationId: "",
    isOnExpirationPeriod: false,
    plan: "",
    price: 0,
    seats: 0,
    updatedAt: undefined,
};
export var initialSubscriptionItem = {
    id: "",
    price: {
        id: "",
        currency: "",
        unit_amount: 0,
        type: "",
        recurring: { interval: "month", interval_count: 1, usage_type: "" },
        billing_scheme: "",
        object: "",
        product: "",
    },
    quantity: 0,
};
