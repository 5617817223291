"use client";
import { useQuery } from "@apollo/client";
import { mapProjectWithProgressToProject, } from "../..";
import { initialProjectValue } from "../constants";
import { FIND_TASK_PROJECT } from "../queries/findTaskProject";
export var useFindTaskProject = function (taskId) {
    var _a = useQuery(FIND_TASK_PROJECT, {
        variables: { taskId: taskId },
        fetchPolicy: "cache-first",
    }), data = _a.data, error = _a.error, loading = _a.loading, refetch = _a.refetch;
    return {
        project: loading
            ? initialProjectValue
            : ((data === null || data === void 0 ? void 0 : data.findTaskProject) &&
                mapProjectWithProgressToProject(data.findTaskProject)) ||
                initialProjectValue,
        error: error,
        loading: loading,
        refetch: refetch,
    };
};
