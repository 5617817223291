"use client";
import { useQuery } from "@apollo/client";
import { mapLessonEntityToLesson } from "../..";
import { FIND_MY_LESSONS_WITH_PROGRESS } from "../queries/findMyLessonsWithProgress";
export var useFindMyLessons = function (projectId) {
    var _a = useQuery(FIND_MY_LESSONS_WITH_PROGRESS, {
        variables: { projectId: projectId },
        fetchPolicy: "network-only",
    }), data = _a.data, error = _a.error, loading = _a.loading;
    return {
        lessons: loading
            ? []
            : (data === null || data === void 0 ? void 0 : data.findMyLessons.map(mapLessonEntityToLesson)) || [],
        error: error,
        loading: loading,
    };
};
