"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useMutation } from "@apollo/client";
import { updateProjectTemplateLessonThemes } from "../functions";
import { UPDATE_OWNED_PROJECT_TEMPLATE } from "../mutations/updateOwnedProjectTemplate";
import { FIND_MY_PROJECTS_TEMPLATES } from "../queries/findProjectTemplates";
export var useUpdateOwnedProjectTemplate = function () {
    var _a = useMutation(UPDATE_OWNED_PROJECT_TEMPLATE, {
        refetchQueries: [FIND_MY_PROJECTS_TEMPLATES],
    }), updateSingleProject = _a[0], _b = _a[1], loading = _b.loading, error = _b.error;
    var updateProjectTemplate = function (id, data) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updateSingleProject({
                        variables: {
                            input: {
                                where: { id: id },
                                data: data,
                            },
                        },
                        optimisticResponse: {
                            updateOwnedProjectTemplate: {
                                id: id,
                                //@ts-ignore
                                __typename: "ProjectTemplateEntity",
                                name: data === null || data === void 0 ? void 0 : data.name,
                                githubRepoDistribution: data === null || data === void 0 ? void 0 : data.githubRepoDistribution,
                                livePreviewUrl: data === null || data === void 0 ? void 0 : data.livePreviewUrl,
                            },
                        },
                        update: function (cache) {
                            updateProjectTemplateInCache(cache, id, data);
                            data.theme && updateProjectTemplateLessonThemes(cache)(id, data.theme);
                        },
                    })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); };
    var updateProjectTemplateInCache = function (cache, projectTemplateId, data) {
        var projectTemplateToUpdate = {
            id: projectTemplateId,
            __typename: "ProjectTemplateEntity",
        };
        cache.modify({
            id: cache.identify(projectTemplateToUpdate),
            fields: {
                name: function (prev) {
                    return (data === null || data === void 0 ? void 0 : data.name) || prev;
                },
                subtitle: function (prev) {
                    return (data === null || data === void 0 ? void 0 : data.subtitle) || prev;
                },
                theme: function (prev) {
                    return (data === null || data === void 0 ? void 0 : data.theme) || prev;
                },
                eta: function (prev) {
                    return (data === null || data === void 0 ? void 0 : data.eta) || prev;
                },
                difficultyLevel: function (prev) {
                    return (data === null || data === void 0 ? void 0 : data.difficultyLevel) || prev;
                },
                isExpGainOn: function (prev) {
                    return (data === null || data === void 0 ? void 0 : data.isExpGainOn) || prev;
                },
                livePreviewUrl: function (prev) {
                    return (data === null || data === void 0 ? void 0 : data.livePreviewUrl) || prev;
                },
                githubRepoTemplateName: function (prev) {
                    return (data === null || data === void 0 ? void 0 : data.githubRepoTemplateName) || prev;
                },
                githubRepoDistribution: function (prev) {
                    return (data === null || data === void 0 ? void 0 : data.githubRepoDistribution) || prev;
                },
            },
        });
    };
    return { updateProjectTemplate: updateProjectTemplate, loading: loading, error: error };
};
