"use client";
import { useMutation } from "@apollo/client";
import { UPDATE_TASK_TEMPLATES } from "../mutations/updateTaskTemplates";
import { FIND_OWNED_LESSON_TASK_TEMPLATES } from "../queries/findOwnedLessonTaskTemplates";
export var useUpdateTaskTemplatesOrder = function (lessonTemplateId) {
    var updateExistingTaskTemplatesOrder = useMutation(UPDATE_TASK_TEMPLATES)[0];
    var reorderTaskTemplates = function (taskTemplates) {
        var mappedTaskTemplatesToUpdate = taskTemplates.map(function (element) { return ({
            id: element.id,
            orderIndex: element.orderIndex,
        }); });
        updateExistingTaskTemplatesOrder({
            variables: { data: mappedTaskTemplatesToUpdate },
            optimisticResponse: {
                __typename: "Mutation",
                updateTaskTemplates: mappedTaskTemplatesToUpdate,
            },
            update: function (cache) {
                updateTaskTemplatesOrderInCache(cache, taskTemplates);
            },
        });
    };
    var updateTaskTemplatesOrderInCache = function (cache, taskTemplates) {
        cache.writeQuery({
            query: FIND_OWNED_LESSON_TASK_TEMPLATES,
            variables: { lessonTemplateId: lessonTemplateId },
            data: {
                findOwnedLessonTaskTemplates: taskTemplates,
            },
        });
    };
    return {
        reorderTaskTemplates: reorderTaskTemplates,
    };
};
