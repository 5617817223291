"use client";
import { useQuery } from "@apollo/client";
import { mapLessonEntityToLesson } from "../..";
import { FIND_PROJECT_LESSONS_WITH_PROGRESS } from "../queries/findProjectLessonsWithProgress";
export var useFindProjectLessons = function (projectId) {
    var _a = useQuery(FIND_PROJECT_LESSONS_WITH_PROGRESS, {
        variables: { projectId: projectId },
        fetchPolicy: "network-only",
    }), data = _a.data, error = _a.error, loading = _a.loading, refetch = _a.refetch;
    return {
        lessons: loading
            ? []
            : (data === null || data === void 0 ? void 0 : data.findProjectLessons.map(mapLessonEntityToLesson)) || [],
        error: error,
        loading: loading,
        refetch: refetch,
    };
};
