import { initialLessonValues } from "../lesson/constants";
import { initialTaskSchedule } from "../task-schedule/constants";
import { defaultTaskTemplate } from "../task-template/constants";
import { initialUserValues } from "../user/constants";
export var initialTaskReview = {
    createdAt: new Date(),
    reviewCompletedAt: new Date(),
    reviewRequestedAt: new Date(),
    reviewRequests: 0,
    taskId: "",
    groupId: "",
    updatedAt: new Date(),
    reviewState: "",
    task: {},
    id: "",
};
export var initialTaskEntity = {
    lessonId: "",
    taskReview: initialTaskReview,
    taskScheduleId: "",
    taskSchedule: initialTaskSchedule,
    state: "",
    user: initialUserValues,
    id: "",
    createdAt: "",
    lesson: initialLessonValues,
    orderIndex: 0,
    startedAt: "",
    taskTemplateId: "",
    userId: "",
    taskTemplate: defaultTaskTemplate,
};
export var initialCodeSolution = {
    code: "",
    id: "",
    language: "",
    taskId: "",
};
export var initialTextSolution = {
    solution: {
        content: {},
        createdAt: "",
        id: "",
        updatedAt: "",
    },
    id: "",
    taskId: "",
    solutionId: "",
};
