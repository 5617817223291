"use client";
import { useQuery } from "@apollo/client";
import { FIND_TASK_TAGS } from "../queries/findTaskTags";
export var useFindTaskTags = function (taskTemplateId) {
    var _a = useQuery(FIND_TASK_TAGS, {
        variables: { taskTemplateId: taskTemplateId },
        fetchPolicy: "cache-and-network",
    }), data = _a.data, loading = _a.loading, refetch = _a.refetch, error = _a.error;
    return {
        tags: loading ? [] : (data === null || data === void 0 ? void 0 : data.findTaskTags) || [],
        loading: loading,
        refetch: refetch,
        error: error,
    };
};
