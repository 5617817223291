var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FIND_OWNED_PROJECT_LESSON_TEMPLATES } from "../lesson-template/queries/findProjectLessonTemplates";
export var updateProjectTemplateLessonThemes = function (cache) { return function (projectTemplateId, theme) {
    var findOwnedProjectLessonTemplates = cache.readQuery({
        query: FIND_OWNED_PROJECT_LESSON_TEMPLATES,
        variables: {
            projectTemplateId: projectTemplateId,
        },
    }).findOwnedProjectLessonTemplates;
    var updatedLessons = findOwnedProjectLessonTemplates.map(function (lessonTemplate) { return (__assign(__assign({}, lessonTemplate), { theme: theme })); });
    cache.writeQuery({
        query: FIND_OWNED_PROJECT_LESSON_TEMPLATES,
        data: {
            findOwnedProjectLessonTemplates: updatedLessons,
        },
        variables: {
            projectTemplateId: projectTemplateId,
        },
    });
}; };
export var updateProjectLessonsTheme = function (cache) { return function (projectTemplateId, theme) {
    var projectTemplateToUpdate = {
        id: projectTemplateId,
        __typename: "ProjectTemplateEntity",
    };
    cache.modify({
        id: cache.identify(projectTemplateToUpdate),
        fields: {
            lessonTemplates: function (prev) {
                var mappedLessons = prev.map(function (lessonTemplate) { return (__assign(__assign({}, lessonTemplate), { theme: theme })); });
                return mappedLessons;
            },
        },
    });
}; };
