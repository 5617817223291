export var mapInvoiceEntity = function (invoice) {
    var _a, _b, _c, _d;
    return {
        amountPaid: (invoice === null || invoice === void 0 ? void 0 : invoice.amount_paid) || 0,
        currency: (invoice === null || invoice === void 0 ? void 0 : invoice.currency) || "",
        isPaid: (invoice === null || invoice === void 0 ? void 0 : invoice.paid) || false,
        periodStart: (invoice === null || invoice === void 0 ? void 0 : invoice.period_start) || 0,
        quantity: ((_b = (_a = invoice === null || invoice === void 0 ? void 0 : invoice.lines) === null || _a === void 0 ? void 0 : _a.data[1]) === null || _b === void 0 ? void 0 : _b.quantity) ||
            ((_d = (_c = invoice === null || invoice === void 0 ? void 0 : invoice.lines) === null || _c === void 0 ? void 0 : _c.data[0]) === null || _d === void 0 ? void 0 : _d.quantity) ||
            0,
        invoiceUrl: (invoice === null || invoice === void 0 ? void 0 : invoice.hosted_invoice_url) || "",
        amountDue: (invoice === null || invoice === void 0 ? void 0 : invoice.amount_due) || 0,
        subscriptionQuantity: (invoice === null || invoice === void 0 ? void 0 : invoice.subscription.quantity) || 0,
        nextPaymentAttempt: (invoice === null || invoice === void 0 ? void 0 : invoice.next_payment_attempt) || 0,
    };
};
