"use client";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var mapTaskEntityToUserTask = function (task) {
    var _a, _b, _c;
    if (!task.user) {
        throw Error("No user include found");
    }
    var taskAssignee = !!((_a = task === null || task === void 0 ? void 0 : task.taskSchedule) === null || _a === void 0 ? void 0 : _a.assignee)
        ? {
            userEmail: task.taskSchedule.assignee.email,
            avatarUrl: ((_b = task.taskSchedule.assignee) === null || _b === void 0 ? void 0 : _b.avatarUrl) || "",
            username: ((_c = task.taskSchedule.assignee) === null || _c === void 0 ? void 0 : _c.username) || "",
        }
        : {
            userEmail: task.user.email,
            avatarUrl: task.user.avatarUrl || "",
            username: task.user.username || "",
        };
    return __assign({ id: task.id, orderIndex: task.orderIndex, reviewState: task.taskReview.reviewState, state: task.state }, taskAssignee);
};
