"use client";
import { useQuery } from "@apollo/client";
import { initialTextSolution } from "../constants";
import { FIND_TASK_TEXT_SOLUTION } from "../queries/findTaskTextSolution";
export var useFindTaskTextSolution = function (taskId) {
    var _a = useQuery(FIND_TASK_TEXT_SOLUTION, {
        variables: {
            taskId: taskId,
        },
    }), data = _a.data, loading = _a.loading;
    return {
        textSolution: loading
            ? initialTextSolution
            : ((data === null || data === void 0 ? void 0 : data.findTaskTextSolution) && data.findTaskTextSolution) ||
                initialTextSolution,
        loading: loading,
    };
};
