import { initialProjectTemplate } from "../project-template/constants";
export var initialProjectSchedule = {
    id: "",
    lessonSchedules: [],
    programId: "",
    isPublic: false,
    collaborators: [],
    projectTemplate: initialProjectTemplate,
    projectTemplateId: "",
    collaborationType: "",
    projects: [],
    state: "",
};
