"use client";
import { useMutation } from "@apollo/client";
import { UPDATE_MY_LESSON_TEMPLATES } from "../mutations/updateLessonTemplates";
import { FIND_OWNED_PROJECT_LESSON_TEMPLATES } from "../queries/findProjectLessonTemplates";
export var useUpdateLessonTemplatesOrder = function (projectTemplateId) {
    var updateExistingLessonTemplatesOrder = useMutation(UPDATE_MY_LESSON_TEMPLATES)[0];
    var reorderLessonTemplates = function (lessonTemplates) {
        var mappedLessonTemplatesToUpdate = lessonTemplates.map(function (element) { return ({
            id: element.id,
            orderIndex: element.orderIndex,
        }); });
        updateExistingLessonTemplatesOrder({
            variables: { data: mappedLessonTemplatesToUpdate },
            optimisticResponse: {
                __typename: "Mutation",
                updateMyLessonTemplates: mappedLessonTemplatesToUpdate,
            },
            update: function (cache) {
                updateLessonTemplatesOrderInCache(cache, lessonTemplates);
            },
        });
    };
    var updateLessonTemplatesOrderInCache = function (cache, lessonTemplates) {
        cache.writeQuery({
            query: FIND_OWNED_PROJECT_LESSON_TEMPLATES,
            variables: { projectTemplateId: projectTemplateId },
            data: {
                findOwnedProjectLessonTemplates: lessonTemplates,
            },
        });
    };
    return {
        reorderLessonTemplates: reorderLessonTemplates,
    };
};
