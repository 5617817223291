"use client";
import { useQuery } from "@apollo/client";
import { initialSubscription } from "../constants";
import { FIND_MY_SUBSCRIPTION } from "../queries/findMySubscription";
export var useFindMySubscription = function () {
    var _a = useQuery(FIND_MY_SUBSCRIPTION), data = _a.data, loading = _a.loading;
    return {
        subscription: loading
            ? initialSubscription
            : (data === null || data === void 0 ? void 0 : data.findMySubscription) || initialSubscription,
        loading: loading,
    };
};
