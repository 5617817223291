import { FIND_GROUPS_ACCESS_REQUESTS } from "./queries/findGroupAccessRequests";
export var updateGroupAccessPlanInCache = function (cache, groupAccessId, plan) {
    var groupToUpdate = {
        id: groupAccessId,
        __typename: "GroupAccessRequest",
    };
    cache.modify({
        id: cache.identify(groupToUpdate),
        fields: {
            plan: function (prev) {
                return plan || prev;
            },
        },
    });
};
export var updateGroupAccessStatusInCache = function (cache, groupAccessId, status) {
    var groupToUpdate = {
        id: groupAccessId,
        __typename: "GroupAccessRequest",
    };
    cache.modify({
        id: cache.identify(groupToUpdate),
        fields: {
            status: function (prev) {
                return status || prev;
            },
        },
    });
};
export var removeGroupAccessEntity = function (cache, groupId, accessId) {
    var data = cache.readQuery({
        query: FIND_GROUPS_ACCESS_REQUESTS,
        variables: {
            groupId: groupId,
        },
    });
    var updatedGroupAccesses = data.findGroupAccessRequests.filter(function (groupAccess) { return groupAccess.id !== accessId; });
    cache.writeQuery({
        query: FIND_GROUPS_ACCESS_REQUESTS,
        variables: {
            groupId: groupId,
        },
        data: {
            findGroupAccessRequests: updatedGroupAccesses,
        },
    });
};
