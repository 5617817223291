"use client";
import { useQuery } from "@apollo/client";
import { initialTaskSchedule } from "../constants";
import { mapTaskScheduleEntity } from "../mappers/taskScheduleQueryMapper";
import { FIND_TASK_SCHEDULE_WITH_TASKS } from "../queries/findTaskScheduleWithTasks";
var initialMappedTaskSchedule = mapTaskScheduleEntity(initialTaskSchedule);
export var useFindTaskScheduleWithTasks = function (id) {
    var _a = useQuery(FIND_TASK_SCHEDULE_WITH_TASKS, {
        variables: {
            id: id,
        },
        fetchPolicy: "cache-first",
    }), data = _a.data, loading = _a.loading;
    return {
        taskSchedule: loading
            ? initialMappedTaskSchedule
            : ((data === null || data === void 0 ? void 0 : data.findTaskScheduleWithTasks) &&
                mapTaskScheduleEntity(data.findTaskScheduleWithTasks)) ||
                initialMappedTaskSchedule,
        loading: loading,
    };
};
