import { initialDocumentValues } from "../document/constants";
export var initialLessonTemplateValues = {
    isPublished: false,
    name: "",
    brief: initialDocumentValues,
    briefId: "",
    orderIndex: 0,
    projectTemplateId: "",
    theme: "",
    id: "",
    taskTemplates: [],
    createdAt: "",
};
