"use client";
import { useQuery } from "@apollo/client";
import { mapInvoiceEntity } from "../mappers/mapInvoiceEntity";
import { FIND_MY_INVOICES } from "../queries/findMyInvoices";
export var useFindMyInvoices = function () {
    var _a = useQuery(FIND_MY_INVOICES, {
        fetchPolicy: "network-only",
    }), data = _a.data, loading = _a.loading;
    return {
        invoices: loading ? [] : (data === null || data === void 0 ? void 0 : data.findMyInvoices.map(mapInvoiceEntity)) || [],
        loading: loading,
    };
};
