"use client";
import { useQuery } from "@apollo/client";
import { initialProgram } from "../constants";
import { FIND_MY_ACTIVE_BOARD_PROGRAM } from "../queries/findMyActiveBoardProgram";
export var useFindMyActiveBoardProgram = function () {
    var _a = useQuery(FIND_MY_ACTIVE_BOARD_PROGRAM), data = _a.data, error = _a.error, loading = _a.loading, refetch = _a.refetch;
    return {
        program: loading
            ? initialProgram
            : (data === null || data === void 0 ? void 0 : data.findMyActiveBoardProgram) || initialProgram,
        error: error,
        loading: loading,
        refetch: refetch,
    };
};
