"use client";
import { useQuery } from "@apollo/client";
import { initialRating } from "../constants";
import { FIND_USER_RATING } from "../queries/findUserRating";
export var useFindUserRating = function (userId) {
    var _a = useQuery(FIND_USER_RATING, {
        variables: { userId: userId },
    }), data = _a.data, loading = _a.loading;
    return {
        rating: loading ? initialRating : (data === null || data === void 0 ? void 0 : data.findUserRating) || initialRating,
    };
};
