"use client";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useMutation, useQuery } from "@apollo/client";
import { initialPullCondition, initialReviewCondition } from "../constants";
import { UPDATE_PULL_CONDITIONS } from "../mutations/updatePullRequestConditions";
import { UPDATE_REVIEW_CONDITIONS } from "../mutations/updateReviewConditions";
import { FIND_CODE_REVIEW_CONDITIONS } from "../queries/findCodeReviewConditions";
import { FIND_PULL_REQUEST_CONDITIONS } from "../queries/findPullRequestConditions";
export var useGitConditions = function (taskTemplateId) {
    var _a, _b;
    var _c = useMutation(UPDATE_PULL_CONDITIONS), updatePull = _c[0], updatePRRequest = _c[1];
    var _d = useMutation(UPDATE_REVIEW_CONDITIONS), updateCodeReview = _d[0], updateCRRequest = _d[1];
    var pullConditions = useQuery(FIND_PULL_REQUEST_CONDITIONS, {
        variables: {
            taskTemplateId: taskTemplateId,
        },
    });
    var codeReviewConditions = useQuery(FIND_CODE_REVIEW_CONDITIONS, {
        variables: {
            taskTemplateId: taskTemplateId,
        },
    });
    var updatePullRequestConditions = function (id, conditions) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updatePull({
                        variables: {
                            id: id,
                            conditions: conditions,
                        },
                        optimisticResponse: {
                            updatePullRequestConditions: __assign({ id: id, 
                                //@ts-ignore
                                __typename: "PullConditionEntity" }, conditions),
                        },
                        update: function (_cache, _a) {
                            var data = _a.data;
                            if (!(data === null || data === void 0 ? void 0 : data.updatePullRequestConditions))
                                return;
                            updatePullConditionsInCache(id, conditions);
                        },
                    })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); };
    var updateCodeReviewConditions = function (id, conditions) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updateCodeReview({
                        variables: {
                            id: id,
                            conditions: conditions,
                        },
                        optimisticResponse: {
                            updateReviewConditions: __assign({ id: id, 
                                //@ts-ignore
                                __typename: "ReviewConditionEntity" }, conditions),
                        },
                        update: function (_cache, _a) {
                            var data = _a.data;
                            if (!(data === null || data === void 0 ? void 0 : data.updateReviewConditions))
                                return;
                            updateCodeReviewConditionsInCache(id, conditions);
                        },
                    })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); };
    var updatePullConditionsInCache = function (pullConditionId, conditions) {
        var cache = updatePRRequest.client.cache;
        var pullConditionToUpdate = {
            id: pullConditionId,
            __typename: "PullConditionEntity",
        };
        cache.modify({
            id: cache.identify(pullConditionToUpdate),
            fields: {
                isOn: function (prev) {
                    return conditions.isOn || prev;
                },
                isMergeRequired: function (prev) {
                    return conditions.isMergeRequired || prev;
                },
            },
        });
    };
    var updateCodeReviewConditionsInCache = function (reviewConditionId, conditions) {
        var cache = updatePRRequest.client.cache;
        var reviewConditionToUpdate = {
            id: reviewConditionId,
            __typename: "ReviewConditionEntity",
        };
        cache.modify({
            id: cache.identify(reviewConditionToUpdate),
            fields: {
                isOn: function (prev) {
                    return conditions.isOn || prev;
                },
                isRequired: function (prev) {
                    return conditions.isRequired || prev;
                },
            },
        });
    };
    return {
        updatePRRequest: updatePRRequest,
        updateCRRequest: updateCRRequest,
        updatePullRequestConditions: updatePullRequestConditions,
        updateCodeReviewConditions: updateCodeReviewConditions,
        codeReviewConditions: codeReviewConditions.loading
            ? initialReviewCondition
            : ((_a = codeReviewConditions.data) === null || _a === void 0 ? void 0 : _a.findReviewConditions) ||
                initialReviewCondition,
        pullRequestConditions: pullConditions.loading
            ? initialPullCondition
            : ((_b = pullConditions.data) === null || _b === void 0 ? void 0 : _b.findPullRequestConditions) || initialPullCondition,
    };
};
