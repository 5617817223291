"use client";
import { useQuery } from "@apollo/client";
import { mapProjectTemplateToPublic } from "../mappers/projectTemplateQueryMappers";
import { FIND_PUBLIC_PROJECTS_TEMPLATES } from "../queries/findPublicProjectTemplates";
export var useFindPublicProjectTemplates = function () {
    var _a = useQuery(FIND_PUBLIC_PROJECTS_TEMPLATES, {
        fetchPolicy: "cache-first", // Used for first execution
    }), data = _a.data, loading = _a.loading, refetch = _a.refetch;
    return {
        projectsTemplates: loading
            ? []
            : ((data === null || data === void 0 ? void 0 : data.findPublicProjectTemplates) &&
                data.findPublicProjectTemplates.map(mapProjectTemplateToPublic)) ||
                [],
        loading: loading,
        refetch: refetch,
    };
};
