"use client";
import { useQuery } from "@apollo/client";
import { initialDocumentValues } from "../../document/constants";
import { FIND_PUBLIC_PROJECT_TEMPLATE_DOCUMENT } from "../queries/findPublicProjectTemplateDocument";
export var useFindPublicProjectTemplateDocument = function (projectTemplateId) {
    var _a = useQuery(FIND_PUBLIC_PROJECT_TEMPLATE_DOCUMENT, {
        variables: { id: projectTemplateId },
        fetchPolicy: "network-only",
    }), data = _a.data, error = _a.error, loading = _a.loading, refetch = _a.refetch;
    return {
        document: loading
            ? initialDocumentValues
            : (data === null || data === void 0 ? void 0 : data.findPublicProjectTemplateDocument) || initialDocumentValues,
        error: error,
        loading: loading,
        refetch: refetch,
    };
};
