"use client";
import { useQuery } from "@apollo/client";
import { FIND_USER_PROJECT_TEMPLATE_EVALUATION } from "../queries/findUserProjectTemplateEvaluation";
export var useFindUserProjectTemplateEvaluation = function (projectTemplateId) {
    var _a = useQuery(FIND_USER_PROJECT_TEMPLATE_EVALUATION, {
        variables: { projectTemplateId: projectTemplateId },
        fetchPolicy: "cache-first",
    }), data = _a.data, loading = _a.loading, error = _a.error;
    return {
        evaluation: loading
            ? null
            : (data === null || data === void 0 ? void 0 : data.findUserProjectTemplateEvaluation) || null,
        loading: loading,
        error: error,
    };
};
