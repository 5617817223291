"use client";
import { useQuery } from "@apollo/client";
import { mapUserSkillsToUserSkillsWithPublicUserData, } from "../..";
import { GET_USER_SKILLS } from "../queries/getUserSkills";
/**
 * Hook to get user skills.
 * This hook uses the Apollo Client's useQuery hook to fetch user skills from the GraphQL server.
 *
 * @returns {Object} An object containing the following properties:
 * - userSkills: The user's skills. This is null if the data is still loading.
 * - loading: A boolean indicating whether the data is still loading.
 * - error: Any error that occurred while fetching the data.
 * - refetch: A function to refetch the data.
 */
export var useGetUserSkills = function () {
    // Use Apollo Client's useQuery hook to fetch user skills from the GraphQL server.
    var _a = useQuery(GET_USER_SKILLS), data = _a.data, loading = _a.loading, error = _a.error, refetch = _a.refetch;
    return {
        // If the data is still loading, userSkills is null.
        // Otherwise, userSkills is the fetched data, mapped to include public user data.
        userSkills: loading
            ? null
            : ((data === null || data === void 0 ? void 0 : data.getUserSkills) &&
                mapUserSkillsToUserSkillsWithPublicUserData(data.getUserSkills)) ||
                null,
        // Whether the data is still loading.
        loading: loading,
        // Any error that occurred while fetching the data.
        error: error,
        // A function to refetch the data.
        refetch: refetch,
    };
};
