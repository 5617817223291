import { initialReviewCondition } from "../../task-template/constants";
import { Theme } from "../../types/graphql";
import { mapTaskScheduleTasksToTasks } from "./mapTaskScheduleTasksToTasks";
export var mapTaskScheduleEntity = function (taskSchedule) {
    var _a, _b;
    if (!taskSchedule.taskTemplate) {
        throw Error("TaskTemplate include not found");
    }
    if (!taskSchedule.taskTemplate.lessonTemplate) {
        throw Error("LessonTemplate include not found");
    }
    if (!taskSchedule.taskTemplate.tags) {
        throw Error("Tags include not found");
    }
    return {
        id: taskSchedule.id,
        briefId: taskSchedule.taskTemplate.briefId,
        taskTemplateId: taskSchedule.taskTemplateId,
        orderIndex: taskSchedule.taskTemplate.orderIndex,
        tasks: mapTaskScheduleTasksToTasks(taskSchedule) || [],
        reviewConditions: taskSchedule.taskTemplate.reviewCondition || initialReviewCondition,
        taskType: taskSchedule.taskTemplate.type,
        lessonScheduleId: taskSchedule.lessonScheduleId,
        tags: taskSchedule.taskTemplate.tags.map(function (tag) { return tag.name; }),
        assignee: taskSchedule.assignee
            ? {
                id: taskSchedule.assignee.id,
                avatarUrl: taskSchedule.assignee.avatarUrl || "",
                username: taskSchedule.assignee.username || "",
                email: taskSchedule.assignee.email,
            }
            : null,
        lessonName: ((_a = taskSchedule.taskTemplate.lessonTemplate) === null || _a === void 0 ? void 0 : _a.name) ||
            "Lesson name not specified",
        taskName: taskSchedule.taskTemplate.title,
        theme: ((_b = taskSchedule.taskTemplate.lessonTemplate) === null || _b === void 0 ? void 0 : _b.theme) || Theme.DEFAULT,
    };
};
