import { useQuery } from "@apollo/client";
import { FIND_TEAM_MEMBERS } from "../queries/findTeamMembers";
export var useFindTeamMembers = function (teamId) {
    var _a = useQuery(FIND_TEAM_MEMBERS, {
        variables: { teamId: teamId },
    }), data = _a.data, loading = _a.loading, error = _a.error;
    return {
        members: loading ? [] : (data === null || data === void 0 ? void 0 : data.findTeamMembers) || [],
        loading: loading,
        error: error,
    };
};
