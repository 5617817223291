import { FIND_UNRESOLVED_ORG_TASK_REVIEWS } from "../queries/findUnresolvedOrgTaskReviews";
export var updateTaskReviewStateInCache = function (cache, taskReviewId, state) {
    var reviewToUpdate = {
        id: taskReviewId,
        __typename: "TaskReviewEntity",
    };
    cache.modify({
        id: cache.identify(reviewToUpdate),
        fields: {
            reviewState: function () {
                return state;
            },
        },
    });
};
export var updateUnresolvedTasksReviews = function (cache, taskReviewId) {
    var reviews = cache.readQuery({
        query: FIND_UNRESOLVED_ORG_TASK_REVIEWS,
    });
    var filteredReviews = reviews.findUnresolvedOrgTaskReviews.filter(function (review) { return review.id !== taskReviewId; });
    cache.writeQuery({
        query: FIND_UNRESOLVED_ORG_TASK_REVIEWS,
        data: {
            findUnresolvedOrgTaskReviews: filteredReviews,
        },
    });
};
