import { useMutation } from "@apollo/client";
import { REVOKE_TEAM_API_KEY } from "../mutations/revokeTeamApiKey";
export var useRevokeTeamApiKey = function () {
    var _a = useMutation(REVOKE_TEAM_API_KEY, {
        update: function (cache, _a) {
            var data = _a.data;
            if (data === null || data === void 0 ? void 0 : data.revokeTeamApiKey) {
                cache.modify({
                    id: cache.identify(data.revokeTeamApiKey),
                    fields: {
                        apiKey: function () { return null; },
                    },
                });
            }
        },
    }), revokeTeamApiKey = _a[0], _b = _a[1], data = _b.data, loading = _b.loading, error = _b.error;
    return { revokeTeamApiKey: revokeTeamApiKey, data: data, loading: loading, error: error };
};
