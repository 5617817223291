"use client";
import { useQuery } from "@apollo/client";
import { SYNC_GROUP_ACCESS_PAYMENT_BY_CHECKOUT_ID } from "../queries/syncGroupAccessPaymentByCheckoutId";
export var useSyncGroupAccessPaymentByCheckoutId = function (checkoutId) {
    var _a = useQuery(SYNC_GROUP_ACCESS_PAYMENT_BY_CHECKOUT_ID, {
        variables: { checkoutId: checkoutId },
    }), data = _a.data, error = _a.error, loading = _a.loading, refetch = _a.refetch;
    return {
        access: (data === null || data === void 0 ? void 0 : data.syncGroupAccessPaymentByCheckoutId) || null,
        error: error,
        loading: loading,
        refetch: refetch,
    };
};
