"use client";
import { GET_TASKS_GITHUB_DETAILS } from "../queries/getTasksGithubDetails";
import { useQuery } from "@apollo/client";
export var useGetGithubTasksDetails = function (where) {
    if (where === void 0) { where = {}; }
    var _a = useQuery(GET_TASKS_GITHUB_DETAILS, { variables: { where: where } }), data = _a.data, loading = _a.loading;
    return {
        githubTasksDetails: loading ? [] : (data === null || data === void 0 ? void 0 : data.findGithubTasksDetails) || [],
        loading: loading,
    };
};
