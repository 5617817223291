"use client";
import { useQuery } from "@apollo/client";
import { mapProjectWithProgressToProject, } from "../..";
import { FIND_TEAM_PROJECTS } from "../queries/findTeamProjects";
export var useFindTeamProjects = function (teamId) {
    var _a = useQuery(FIND_TEAM_PROJECTS, {
        variables: { teamId: teamId },
    }), data = _a.data, error = _a.error, loading = _a.loading, refetch = _a.refetch;
    return {
        projects: loading
            ? []
            : (data === null || data === void 0 ? void 0 : data.findTeamProjects.map(mapProjectWithProgressToProject)) || [],
        error: error,
        loading: loading,
        refetch: refetch,
    };
};
