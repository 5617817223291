"use client";
import { useQuery } from "@apollo/client";
import { initialProgram } from "../constants";
import { FIND_PUBLIC_GROUP_PROGRAM } from "../queries/findPublicProgram";
export var useFindPublicGroupProgram = function (groupId) {
    var _a = useQuery(FIND_PUBLIC_GROUP_PROGRAM, {
        variables: { groupId: groupId },
        fetchPolicy: "cache-first",
    }), data = _a.data, error = _a.error, loading = _a.loading, refetch = _a.refetch;
    return {
        program: loading
            ? initialProgram
            : (data === null || data === void 0 ? void 0 : data.findPublicProgram) || initialProgram,
        error: error,
        loading: loading,
        refetch: refetch,
    };
};
