"use client";
import { useQuery } from "@apollo/client";
import { CALCULATE_USER_PROGRAM_PROGRESS } from "../queries/calculateUserProgramProgress";
var initialProgress = 0;
export var useCalculateUserProgramProgress = function (userId, programId) {
    var _a = useQuery(CALCULATE_USER_PROGRAM_PROGRESS, { variables: { userId: userId, programId: programId } }), data = _a.data, loading = _a.loading;
    return {
        programProgressInPercent: loading
            ? initialProgress
            : (data === null || data === void 0 ? void 0 : data.calculateUserProgramProgress.programProgressInPercent) ||
                initialProgress,
    };
};
