import { SchedulingState, } from "../../types/graphql";
export var mapLessonEntityToLesson = function (_a) {
    var _b;
    var lesson = _a.lesson, progress = _a.progress;
    return {
        id: lesson.id,
        state: lesson.state,
        name: lesson.lessonTemplate.name,
        theme: lesson.lessonTemplate.theme,
        projectId: lesson.projectId,
        isVisibleOnBoard: lesson.isVisibleOnBoard,
        orderIndex: lesson.lessonTemplate.orderIndex || 0,
        lessonTemplateId: lesson.lessonTemplate.id,
        lessonScheduleId: lesson.lessonScheduleId || "",
        scheduleState: ((_b = lesson.lessonSchedule) === null || _b === void 0 ? void 0 : _b.state) || SchedulingState.HIDDEN,
        completedTasks: progress.completedTasksAmount,
        scheduledTasks: progress.scheduledTasksAmount,
    };
};
