"use client";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useQuery } from "@apollo/client";
import { useState } from "react";
import { FIND_TASK_COMMENTS } from "../queries/findTaskComments";
import { TASK_COMMENT_ADDED } from "../subscriptions/taskCommentAdded";
export var useFindTaskComments = function (taskId) {
    var _a = useQuery(FIND_TASK_COMMENTS, { variables: { taskId: taskId } }), data = _a.data, error = _a.error, loading = _a.loading, subscribeToMore = _a.subscribeToMore, fetchMore = _a.fetchMore;
    var _b = useState(false), newCommentReceived = _b[0], setNewCommentReceived = _b[1];
    var subscribeToNotifications = function () {
        subscribeToMore({
            document: TASK_COMMENT_ADDED,
            variables: { taskId: taskId },
            updateQuery: function (prev, _a) {
                var subscriptionData = _a.subscriptionData;
                if (!subscriptionData.data)
                    return prev;
                var taskCommentAdded = subscriptionData.data.taskCommentAdded;
                process.env.NEXT_PUBLIC_NERDY_USER_ID === taskCommentAdded.authorId &&
                    setNewCommentReceived(true);
                return {
                    findTaskComments: __spreadArray(__spreadArray([], prev.findTaskComments, true), [
                        __assign(__assign({}, taskCommentAdded), { createdAt: 
                            // TODO: I don't know but it's not returning createdAt somehow - will investigate that later
                            (taskCommentAdded === null || taskCommentAdded === void 0 ? void 0 : taskCommentAdded.createdAt) || new Date().toISOString() }),
                    ], false),
                };
            },
        });
    };
    var handleToggleCommentReceived = function () {
        setNewCommentReceived(false);
    };
    var comments = loading ? [] : (data === null || data === void 0 ? void 0 : data.findTaskComments) || [];
    return {
        comments: comments,
        error: error,
        loading: loading,
        subscribeToNotifications: subscribeToNotifications,
        handleToggleCommentReceived: handleToggleCommentReceived,
        newCommentReceived: newCommentReceived,
        fetchMore: fetchMore,
    };
};
