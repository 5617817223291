"use client";
import { useQuery } from "@apollo/client";
import { initialUserValues } from "../constants";
import { AUTHED_USER } from "../queries/getAuthedUser";
import { USER_UPDATED } from "../subscriptions/userUpdated";
export var useGetAuthedUser = function (initialUser) {
    var _a = useQuery(AUTHED_USER), data = _a.data, error = _a.error, loading = _a.loading, refetch = _a.refetch, subscribeToMore = _a.subscribeToMore;
    var subscribeToUser = function (userId) {
        subscribeToMore({
            document: USER_UPDATED,
            variables: { userId: userId },
            updateQuery: function (prev, _a) {
                var subscriptionData = _a.subscriptionData;
                if (!subscriptionData.data)
                    return prev;
                var userUpdated = subscriptionData.data.userUpdated;
                return {
                    user: userUpdated,
                };
            },
        });
    };
    return {
        user: loading
            ? initialUser || initialUserValues
            : (data === null || data === void 0 ? void 0 : data.user) || initialUserValues,
        error: error,
        loading: loading,
        refetch: refetch,
        subscribeToUser: subscribeToUser,
    };
};
