"use client";
import { useQuery } from "@apollo/client";
import { GET_TASK_PULLS } from "../queries/getTaskPulls";
export var useGetRepoPulls = function (projectId) {
    var _a = useQuery(GET_TASK_PULLS, {
        variables: { projectId: projectId },
        fetchPolicy: "network-only",
    }), data = _a.data, loading = _a.loading, error = _a.error, refetch = _a.refetch;
    return {
        pulls: loading ? [] : (data === null || data === void 0 ? void 0 : data.pulls) || [],
        error: error,
        refetch: refetch,
        loading: loading,
    };
};
