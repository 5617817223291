"use client";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useQuery } from "@apollo/client";
import { GET_MY_NOTIFICATIONS } from "../queries/getMyNotifications";
import { NOTIFICATION_CREATED } from "../subscriptions/notificationCreated";
export var useFindMyNotifications = function (userId) {
    var _a = useQuery(GET_MY_NOTIFICATIONS), data = _a.data, error = _a.error, loading = _a.loading, subscribeToMore = _a.subscribeToMore, fetchMore = _a.fetchMore;
    var subscribeToNotifications = function () {
        subscribeToMore({
            document: NOTIFICATION_CREATED,
            variables: { userId: userId },
            updateQuery: function (prev, _a) {
                var subscriptionData = _a.subscriptionData;
                if (!subscriptionData.data)
                    return prev;
                var notificationCreated = subscriptionData.data.notificationCreated;
                return {
                    findMyNotifications: __spreadArray(__spreadArray([], prev.findMyNotifications, true), [
                        __assign(__assign({}, notificationCreated), { createdAt: 
                            // TODO: I don't know but it's not returning createdAt somehow - will investigate that later
                            (notificationCreated === null || notificationCreated === void 0 ? void 0 : notificationCreated.createdAt) || new Date().toISOString() }),
                    ], false).sort(function (a, b) { return Date.parse(b.createdAt) - Date.parse(a.createdAt); }),
                };
            },
        });
    };
    var notifications = loading
        ? []
        : (data === null || data === void 0 ? void 0 : data.findMyNotifications) || [];
    return {
        notifications: notifications,
        error: error,
        loading: loading,
        subscribeToNotifications: subscribeToNotifications,
        fetchMore: fetchMore,
    };
};
