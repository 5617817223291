"use client";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useQuery } from "@apollo/client";
import { mapProjectWithProgressToProject, } from "../..";
import { initialProjectValue } from "../constants";
import { FIND_MY_ACTIVE_PROJECT_WITH_PROGRESS } from "../queries/findMyActiveProject";
import { PROJECT_UPDATED } from "../subscriptions/projectUpdated";
export var useFindMyActiveProject = function () {
    var _a = useQuery(FIND_MY_ACTIVE_PROJECT_WITH_PROGRESS, {
        fetchPolicy: "cache-and-network",
    }), data = _a.data, error = _a.error, loading = _a.loading, refetch = _a.refetch, subscribeToMore = _a.subscribeToMore, client = _a.client;
    var subscribeToProjectUpdate = function (projectId) {
        subscribeToMore({
            document: PROJECT_UPDATED,
            variables: { projectId: projectId },
            updateQuery: function (prev, _a) {
                var subscriptionData = _a.subscriptionData;
                if (!subscriptionData.data)
                    return prev;
                var projectUpdated = subscriptionData.data.projectUpdated;
                var findMyActiveProjectQuery = client.cache.readQuery({
                    query: FIND_MY_ACTIVE_PROJECT_WITH_PROGRESS,
                });
                if (!(findMyActiveProjectQuery === null || findMyActiveProjectQuery === void 0 ? void 0 : findMyActiveProjectQuery.findMyActiveProject))
                    return prev;
                var findMyActiveProject = findMyActiveProjectQuery.findMyActiveProject;
                return {
                    findMyActiveProject: __assign(__assign({}, findMyActiveProject), { project: __assign(__assign({}, findMyActiveProject.project), { state: projectUpdated.project.state }) }),
                };
            },
        });
    };
    return {
        project: loading
            ? initialProjectValue
            : ((data === null || data === void 0 ? void 0 : data.findMyActiveProject) &&
                mapProjectWithProgressToProject(data.findMyActiveProject)) ||
                initialProjectValue,
        subscribeToProjectUpdate: subscribeToProjectUpdate,
        error: error,
        loading: loading,
        refetch: refetch,
    };
};
