"use client";
import { useQuery } from "@apollo/client";
import { GET_MY_FEATURES } from "../queries/getMyFeatures";
var initialFeature = {
    view: false,
    update: false,
    create: false,
};
var initialFeatures = {
    settings: initialFeature,
    groups: initialFeature,
    billings: initialFeature,
    team: initialFeature,
};
export var useGetMyLabFeatures = function () {
    var _a = useQuery(GET_MY_FEATURES), data = _a.data, error = _a.error, loading = _a.loading, refetch = _a.refetch;
    return {
        features: loading
            ? initialFeatures
            : (data === null || data === void 0 ? void 0 : data.getMyFeatures) || initialFeatures,
        error: error,
        loading: loading,
        refetch: refetch,
    };
};
